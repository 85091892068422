import React from 'react';
import BookingEngineLayout from '@/layout/BookingEngineLayout';
import { createUseStyles } from 'react-jss';
import { Grid, GridColumn } from 'semantic-ui-react';
import original from '@/assets/images/original.jpg';
import original2 from '@/assets/images/original2.jpg';

const useStyles = createUseStyles(
  {
    errorWrapper: {
      display: 'block',
      // font-family: "Work-Sans", sans-serif,
      fontSize: '1.5rem',
      lineHeight: 'normal',
      margin: '30px 0 0',
      position: 'relative',
      textAlign: 'center',
    },
    headline: { fontWeight: 'bold' },
    message: {},
    image: {
      maxWidth: '100%',
    },
    link: {
      cursor: 'pointer',
    },
  },
  { name: 'ErrorPageView' },
);

const ErrorPageView = () => {
  const classes = useStyles();

  return (
    <BookingEngineLayout showCustomTop={false}>
      <div className={classes.errorWrapper}>
          <Grid columns={2}>
            <GridColumn>
              <label>Amazon</label>
              <img className={classes.image} src="https://s3.us-east-2.amazonaws.com/obmcontent.wavepm-736711/RoomType/14989733/images/56335__DSC2674.jpg"/>
            </GridColumn>
            <GridColumn>
            <label>Original</label>
              <img className={classes.image} src={original}/>
            </GridColumn>
          </Grid>
          <Grid columns={2}>
            <GridColumn>
              <label>Amazon</label>
              <img className={classes.image} src="https://s3.us-east-2.amazonaws.com/obmcontent.wavepm-736711/RoomType/14989733/images/54598__DSC2678.jpg"/>
            </GridColumn>
            <GridColumn>
            <label>Original</label>
              <img className={classes.image} src={original2}/>
            </GridColumn>
          </Grid>

          
      </div>
    </BookingEngineLayout>
  );
};

export default React.memo(ErrorPageView);
