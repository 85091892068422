import { configureStore } from '@reduxjs/toolkit';
import cartReducer from '@/components/cartSlice';
import searchReducer from '@/components/searchSlice';
import menuReducer from '@/components/menuSlice';
import companyReducer from '@/components/companySlice';
import sessionReducer from '@/components/sessionSlice';

export const store = configureStore({
  reducer: {
    cart: cartReducer,
    search: searchReducer,
    menu: menuReducer,
    company: companyReducer,
    session: sessionReducer,
  },
});
