import { useSelector } from 'react-redux';

export const cartSelector = () => useSelector((state) => state.cart);
export const searchSelector = () => useSelector((state) => state.search);
export const menuSelector = () => useSelector((state) => state.menu);
export const companySelector = () => useSelector((state) => state.company);
export const sessionSelector = () => useSelector((state) => state.session);
// export const searchSelector = () => useSelector((state) => state.search);

const selectors = {
  cartSelector,
  searchSelector,
  menuSelector,
  companySelector,
  sessionSelector,
};

export default selectors;
