import React from 'react';
import { Grid, GridColumn } from 'semantic-ui-react';
import './Footer.css';

const InfoPanel = ({property}) => {
  return (
    <div>
      <div className='custom-info-panel'>
        <Grid columns={2}>
          <GridColumn textAlign='left' className='info-left'>
            <div className='contact-content'>
              <a target="_blank" rel="noreferrer" href={property.mapUrl}>
                <div className='contact-title'>{property.name}</div>
                <div>{property.contact.address}</div>
                <div>{property.contact.city}, {property.contact.state} {property.contact.zipcode}</div>
              </a>
              <div className='contact-phone'>{property.contact.phone}</div>
              <div><a onClick={() => window.location = 'mailto:' + property.contact.email} className='contact-email'>{property.contact.email}</a></div>
              <div className='social-media-content'>
                {property != null &&
                  property.propertySocialMediaDataList != null &&
                  property.propertySocialMediaDataList.length > 0 &&
                  property.propertySocialMediaDataList.map(
                    (media, idx) => (
                      <a key={idx} href={media.url} target="_blank" rel="noreferrer"><i className={media.logo + " icon"}></i>{' '}</a>
                    ),
                  )}
              </div>  
            </div>
          </GridColumn>
          <GridColumn textAlign='left' className='info-right'>
              <a target="_blank" rel="noreferrer" href={property.mapUrl}><img alt="" className='map-image' src={property.mapImage}/></a>
            </GridColumn>
        </Grid>          
      </div>
      <div className='responsive-info-panel'>
        <div>
          <div className='contact-title'>{property.name}</div>
            <a target="_blank" rel="noreferrer" href={property.mapUrl}>
              <div>{property.contact.address}</div>
              <div>{property.contact.city}, {property.contact.state} {property.contact.zipcode}</div>
              <div className='contact-phone' onClick={() => window.location = 'tel:' + property.contact.phone}>{property.contact.phone}</div>
            </a>
            <div><a onClick={() => window.location = 'mailto:' + property.contact.email} className='contact-email'>{property.contact.email}</a></div>
            <div className='social-media-content'>
                {property != null &&
                  property.propertySocialMediaDataList != null &&
                  property.propertySocialMediaDataList.length > 0 &&
                  property.propertySocialMediaDataList.map(
                    (media, idx) => (
                      <a key={idx} href={media.url} target="_blank" rel="noreferrer"><i className={media.logo + " icon"}></i>{' '}</a>
                    ),
                  )}
              </div>
          </div>      
          <div className='map-content'>
            <a target="_blank" rel="noreferrer" href={property.mapUrl}><img alt="" className='map-image' src={property.mapImage}/></a>
          </div>
      </div>
    </div>
  );
};
export default React.memo(InfoPanel);
