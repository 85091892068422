export const MENU_ITEMS = {
  BOOK: 'BOOK',
  BOOKRESPONSIVE: 'BOOKRESPONSIVE',
  ROOMS: 'ROOMS',
  CONTACT: 'CONTACT',
  ABOUT: 'ABOUT',
  POLICIES: 'POLICIES',
  MENU: 'MENU',
  GIFTCARDS: 'GIFTCARDS',
};

export const SORT_ITEMS = {
  DEFAULT: 'Default',
  PRICE_HIGH_TO_LOW: 'Price High to Low',
  PRICE_LOW_TO_HIGH: 'Price Low to High',
  OCCUPANCY_HIGH_TO_LOW: 'Occupancy High to Low',
  OCCUPANCY_LOW_TO_HIGH: 'Occupancy Low to High',
};
