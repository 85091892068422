import React from 'react';
import { createUseStyles } from 'react-jss';
import { Container, Form, Grid, GridColumn } from 'semantic-ui-react';
import Colors from '@/constants/colors';
import IconButtons from '@/components/Buttons/IconButtons';
import { searchSelector } from '@/store/selector';
import '../common/Search.css';
import WaveCalendar from '../Calendar/WaveCalendar';

const useStyles = createUseStyles(
  {
    searchPanel: {
      position: 'fixed',
      width: '100% !important',
      height: '350px',
      backgroundColor: Colors.whiteOne,
      bottom: '75px',
      left: '0',
      transform: 'translateY(138%)',
      transition: 'transform .75s cubic-bezier(.23,1,.32,1)',
      zIndex: 9,
    },
    searchPanelActive: {
      transform: 'translateY(0) !important',
    },
    closeSearchButton: {
      float: 'right',
      position: 'relative',
      top: '-5px',
      right: '50px',
      backgroundColor: 'transparent !important',
      '& i': {
        fontSize: '1.2rem',
        color: `${Colors.blackTwo} !important`,
      },
    },
  },
  { name: 'SearchPanel' },
);

const SearchPanel = ({
  show,
  loadingCalendar,
  setShow,
  datesChanged,
  property,
  allResortsClicked,
  properties,
  adults,
  childenrs,
  viewAllRoomsClick,
  propertySelected,
  removeRoomType,
  subtractAdultsValue,
  addAdultsValue,
  subtractChildrensValue,
  addChildrensValue,
}) => {
  const classes = useStyles();
  const search = searchSelector();
  return (
    <Container
      className={
        show === true
          ? [classes.searchPanel, classes.searchPanelActive].join(' ')
          : classes.searchPanel
      }
    >
      <Grid columns={3}>
        <GridColumn textAlign="left" className="resorts-results">
          {search.property.multipleProperties && (
            <div>
              <div
                className={property.id == null ? 'resort-item resort-item-selected' : 'resort-item'}
                onClick={allResortsClicked}
              >
                All Resorts
              </div>
              {properties.length > 0 &&
                properties.map((p, idx) => (
                  <div
                    className={
                      property.id != null && property.id == p.id
                        ? 'resort-item resort-item-selected'
                        : 'resort-item'
                    }
                    key={idx}
                    onClick={() => propertySelected(p)}
                  >
                    {p.name}
                  </div>
                ))}
            </div>
          )}
          {!search.property.multipleProperties && (
            <div className="resort-item resort-item-selected">{property.name}</div>
          )}
          {search.roomType != null && (
            <div className="resort-item resort-item-selected">
              {search.roomType.name}{' '}
              <a className="remove-roomtype-button" onClick={removeRoomType}>
                Remove x
              </a>
            </div>
          )}
          <a className="view-all-rooms-link" onClick={viewAllRoomsClick}>
            View All Rooms
          </a>
        </GridColumn>
        <GridColumn textAlign="center">
          <WaveCalendar
            availability={search.availability}
            onDateChanged={datesChanged}
            roomType={search.roomType}
            rates={search.rates}
          />
          <div
            className={
              loadingCalendar === true
                ? 'calendar-dimmer calendar-dimmer-active'
                : 'calendar-dimmer'
            }
          >
            <Form className="calendar-dimmer-form" loading={loadingCalendar}></Form>
          </div>
        </GridColumn>
        <GridColumn textAlign="right">
          <Grid columns={1}>
            <GridColumn textAlign="center" className="guest-tittle">
              Guests
              <IconButtons.Close
                className={classes.closeSearchButton}
                onClick={() => setShow(false)}
              />
            </GridColumn>
          </Grid>
          <Grid columns={2}>
            <GridColumn textAlign="right" className="adults">
              <label>{adults}</label> {adults > 1 ? 'adults' : 'adult'}
            </GridColumn>
            <GridColumn textAlign="left" className="guest-butttons-container">
              <a
                className={adults > 1 ? 'guest-button' : 'guest-button guest-button-disabled'}
                onClick={subtractAdultsValue}
              >
                -
              </a>
              <a className="guest-button guest-button-right" onClick={addAdultsValue}>
                +
              </a>
            </GridColumn>
          </Grid>
          <Grid columns={2}>
            <GridColumn textAlign="right" className="adults">
              <label>{childenrs}</label> {childenrs > 0 ? 'childrens' : 'children'}
            </GridColumn>
            <GridColumn textAlign="left" className="guest-butttons-container">
              <a
                className={childenrs > 0 ? 'guest-button' : 'guest-button guest-button-disabled'}
                onClick={subtractChildrensValue}
              >
                -
              </a>
              <a className="guest-button guest-button-right" onClick={addChildrensValue}>
                +
              </a>
            </GridColumn>
          </Grid>
        </GridColumn>
      </Grid>
    </Container>
  );
};
export default React.memo(SearchPanel);
