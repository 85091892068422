import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import Colors from '@/constants/colors';

const IconButton = React.memo(({ icon, color, onClick, circular, ...props }) => (
  <Button className="white" onClick={onClick} size="tiny" circular={circular} icon {...props}>
    <Icon name={icon} style={{ color }} />
  </Button>
));

IconButton.displayName = 'IconButton';

const Edit = (props) => <IconButton icon="pencil" color={Colors.grayThree} {...props} />;

const Remove = (props) => <IconButton icon="trash" color={Colors.redOne} {...props} />;

const Add = (props) => <IconButton icon="add" color={Colors.greenOne} {...props} />;

const Close = (props) => <IconButton icon="close" color={Colors.grayThree} {...props} />;

const Next = (props) => (
  <IconButton icon="chevron right" color={Colors.grayThree} circular {...props} />
);

const Previous = (props) => (
  <IconButton icon="chevron left" color={Colors.grayThree} circular {...props} />
);

const IconButtons = { Edit, Remove, Add, Close, Next, Previous };

export default IconButtons;
