import api from './api';

let sessionRequest = {
  username: 'api@wavepm.com',
  password: 'bkeng2023!',
};

const URLS = {
  session: 'session',
};

export const getSession = () => {
  return api.post(URLS.session, sessionRequest);
};
