import React from 'react';
import HTMLHelper from '@/helpers/htmlHelper';
import './Footer.css';

const Footer = ({ property }) => {
  let footerImageStyle = null;
  let footerClass = "";
  if (property.footerImage != null) {
    footerImageStyle = { backgroundImage: 'url("' + encodeURI(property.footerImage) + '")' };
    footerClass = "footer";
  } else {
    footerImageStyle = { height: '155px' };
    footerClass = "footer footer-without-image";
  }

  return (
    <div className={footerClass} style={footerImageStyle}>
      <a className='top-button' onClick={() => HTMLHelper.scrollToTop()}><i className='arrow up icon'></i></a>
      {property.footerImage != null && (
        <div className="footer-info">
          <div className="footer-info-content">
            <div>
              <img src={property.companyLogo}></img>
            </div>
            <div className="footer-company-info">Managed by {property.contact.companyName} - {property.companyInfo}</div>
          </div>
        </div>
      )}
      {property.footerImage == null && (
        <div className="footer-info-content-1">
          <div>
            <img src={property.companyLogo}></img>
          </div>
          <div className="footer-company-info">Managed by {property.contact.companyName} - {property.companyInfo}</div>
        </div>
      )}
    </div>
  );
};
export default React.memo(Footer);
