import React from 'react';
import { createUseStyles } from 'react-jss';
import { Container, Grid, GridColumn } from 'semantic-ui-react';
import Colors from '@/constants/colors';
import IconButtons from '@/components/Buttons/IconButtons';
import { searchSelector } from '@/store/selector';
import 'flatpickr/dist/themes/airbnb.css';
import Moment from 'moment';
import '../common/Search.css';

const useStyles = createUseStyles(
  {
    searchResponsivePanel: {
      position: 'fixed',
      width: '100% !important',
      height: '100%',
      backgroundColor: Colors.whiteOne,
      top: '0',
      left: '0',
      transform: 'translateY(138%)',
      transition: 'transform .75s cubic-bezier(.23,1,.32,1)',
      zIndex: 99,
      borderTop: '1px solid #ccc',
    },
    searchResponsivePanelActive: {
      composes: ['$searchResponsivePanel'],
      transform: 'translateY(0) !important',
    },
    closeSearchDatesButton: {
      position: 'absolute',
      top: '65px',
      right: '0px',
      left: '0px',
      marginRight: '0px !important',
      marginLeft: '0px !important',
      backgroundColor: 'transparent !important',
      '& i': {
        fontSize: '25px',
        color: `${Colors.blackTwo} !important`,
      },
    },
  },
  { name: 'SearchPanel' },
);
const SearchPanel = ({showResponsive, setShowResponsive, searchParams, viewAllRoomsResponsiveClick, datesCLick, adults, childenrs, removeRoomType, subtractAdultsValue, addAdultsValue, subtractChildrensValue, addChildrensValue}) => {
  const classes = useStyles();
  const search = searchSelector();
  return (
    <div
        className={
          showResponsive === true
            ? classes.searchResponsivePanelActive
            : classes.searchResponsivePanel
        }
      >
        <Container>
          <IconButtons.Close
            className={classes.closeSearchDatesButton}
            onClick={() => setShowResponsive(false)}
          />
          <div className="search-content">
            <Grid columns={1} className="roomtype-responsive-grid">
              <GridColumn textAlign="center">
                {search.roomType != null && (
                  <div className="roomtype-responsive">
                    {search.roomType.name}{' '}
                    <a className="remove-roomtype-button" onClick={removeRoomType}>
                      Remove x
                    </a>
                  </div>
                )}
              </GridColumn>
            </Grid>
            <Grid columns={1}>
              <GridColumn textAlign="center">
                <div className="dates-content">
                  <Grid columns={3} onClick={datesCLick}>
                    <GridColumn textAlign="center">
                      {searchParams.arrival != null
                        ? Moment(searchParams.arrival).format('MM/D/YYYY')
                        : 'Arrival'}
                    </GridColumn>
                    <GridColumn textAlign="center">
                      <i className="arrow right icon"></i>{' '}
                    </GridColumn>
                    <GridColumn textAlign="center">
                      {searchParams.departure != null
                        ? Moment(searchParams.departure).format('MM/D/YYYY')
                        : 'Departure'}
                    </GridColumn>
                  </Grid>
                </div>
              </GridColumn>
            </Grid>
            <Grid columns={1}>
              <GridColumn textAlign="center">
                <Grid columns={2}>
                  <GridColumn textAlign="left" className="adults">
                    <label>{adults}</label> {adults > 1 ? 'adults' : 'adult'}
                  </GridColumn>
                  <GridColumn textAlign="right" className="guest-butttons-container">
                    <a
                      className={
                        adults > 1
                          ? 'guest-button guest-button-left'
                          : 'guest-button guest-button-left guest-button-disabled'
                      }
                      onClick={subtractAdultsValue}
                    >
                      -
                    </a>
                    <a
                      className="guest-button guest-button-right guest-button-right-responsive"
                      onClick={addAdultsValue}
                    >
                      +
                    </a>
                  </GridColumn>
                </Grid>
                <Grid columns={2}>
                  <GridColumn textAlign="left" className="adults">
                    <label>{childenrs}</label> {childenrs > 0 ? 'childrens' : 'children'}
                  </GridColumn>
                  <GridColumn textAlign="right" className="guest-butttons-container">
                    <a
                      className={
                        childenrs > 0
                          ? 'guest-button guest-button-left'
                          : 'guest-button guest-button-left guest-button-disabled'
                      }
                      onClick={subtractChildrensValue}
                    >
                      -
                    </a>
                    <a className="guest-button guest-button-right" onClick={addChildrensValue}>
                      +
                    </a>
                  </GridColumn>
                </Grid>
              </GridColumn>
            </Grid>
            <Grid>
              <GridColumn textAlign="center">
                <a className="view-all-rooms-link" onClick={viewAllRoomsResponsiveClick}>
                  View All Rooms
                </a>
              </GridColumn>
            </Grid>
          </div>
        </Container>
      </div>
  );
};
export default React.memo(SearchPanel);
