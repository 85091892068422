import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import BookingEngineLayout from '@/layout/BookingEngineLayout';
import Search from '@/components/common/Search';
import About from '@/components/common/About';
import Policies from '@/components/common/Policies';
import GiftCard from '@/components/common/GiftCard';
import MenuResponsive from '@/components/common/MenuResponsive';
import Top from '@/components/common/Top';
import Footer from '@/components/common/Footer';
import InfoPanel from '@/components/common/InfoPanel';
import { getPropertyData, getPropertyIdByFriendlyUrl } from '@/api/propertyAPI';
import { getSession } from '@/api/sessionAPI';
import { createUseStyles } from 'react-jss';
import '@/assets/flickity.css';
import './PropertyView.css';
import { Loader } from 'semantic-ui-react';
import HTMLHelper from '@/helpers/htmlHelper';
import { menuSelector } from '@/store/selector';
import { MENU_ITEMS } from 'utils/constants';
import { selectItem, setFriendlyUrl } from '@/components/menuSlice';
import { setCompanyProperty } from '@/components/companySlice';
import { setSession } from '@/components/sessionSlice';
import { sessionSelector } from '@/store/selector';
import { setMainProperty } from '@/components/searchSlice';
import PropertySection from '@/components/common/PropertySection';
import RoomsSection from '@/components/common/RoomsSection';
import RoomTypesSection from '@/components/common/RoomTypesSection';
import { setWindowSize } from '@/components/searchSlice';
import RoomTypePreviewPanel from '@/components/common/RoomTypePreviewPanel';

const useStyles = createUseStyles(
  {
    root: {
      width: '100%',
      overflowY: 'auto',
    },
    roomTypeItemContainer: {
      display: 'none',
    },
    roomTypeItemContainerActive: {
      marginTop: '20px',
    },
    image: {
      width: '100%',
    },
    content: {
      marginLeft: '30px',
      width: '100%',
    },
    back: (properties) => ({
      backgroundColor: properties.propertyColor,
      padding: '100px',
      maxHeight: '700px',
      marginTop: '100px',
      marginBottom: '100px',
      marginLeft: '200px',
      marginRight: '200px',
      color: properties.fontColor + ' !important;',
      '& > a': {
        color: properties.fontColor + ' !important;',
      },
    }),
    backTop: (properties) => ({
      backgroundColor: properties.propertyColor,
      position: 'absolute',
      height: '100px',
      width: '100%',
      top: '45%',
      opacity: '0.5',
    }),
    sliderImage: {
      opacity: 0.2,
      left: '0%',
      width: '100%',
      height: '100%',
      zIndex: 0,
      position: 'absolute',
    },
    [`@media (max-width: 1200px)`]: {
      back: {
        padding: '50px !important',
        marginBottom: '0px !important',
        marginLeft: '0px !important',
        marginRight: '0px !important',
      },
    },
  },
  { name: 'PropertyView' },
);

const PropertyView = () => {
  const [backClolor, setBackClolor] = useState({});
  const [fontColor, setFontColor] = useState({});
  const dispatch = useDispatch();
  const classes = useStyles({ propertyColor: backClolor, fontColor: fontColor });
  const location = useLocation();
  const params = useParams();
  const parameters = new URLSearchParams(location.search);
  const menu = menuSelector();
  const [property, setProperty] = useState({});
  const [heroImageStyle, setHeroImageStyle] = useState({});
  const roomsSection = useRef(null);
  const contactSection = useRef(null);
  const [loading, setLoading] = useState({ loading: true });
  const navigate = useNavigate();
  const session = sessionSelector();
  const [itemForPreview, setItemForPreview] = useState({ itemForPreview: null });
  const [showPreview, setShowPreview] = useState(false);
  const [showGiftCard, setShowGiftCard] = useState(false);

  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const onClickImageContainer = () => {
    setShowPreview(!showPreview);
  };

  const handleItemSelected = (item) => {
    setShowPreview(true);
    setItemForPreview(item);
  };

  function propertyData(propertyId) {
    getPropertyData({
      propertyId: propertyId,
    })
      .then((response) => {
        dispatch(setCompanyProperty(response.data));
        setProperty(response.data);
        setBackClolor(response.data.color);
        setFontColor(response.data.fontColor);
        setLoading(false);
        document.title = response.data.name;
        let heroUrl =
          windowSize.current[0] > 1200 ? response.data.heroImage : response.data.heroMobileImage;
        setHeroImageStyle({
          backgroundSize: 'cover',
          height: windowSize.current[1] - 140 + 'px',
          backgroundImage: 'url("' + encodeURI(heroUrl) + '")',
        });
        dispatch(
          setMainProperty({
            property: response.data,
          }),
        );
      })
      .catch(() => {
        navigate('/error');
      });
  }

  useEffect(() => {
    getSession().then((response) => {
      dispatch(setSession(response.data.token));
    });
    dispatch(
      setWindowSize({
        windowSize: windowSize.current[0],
      }),
    );
  }, []);

  useEffect(() => {
    if (session?.token) {
      if (params?.friendlyUrl) {
        getPropertyIdByFriendlyUrl({ friendlyUrl: params.friendlyUrl })
          .then((response) => {
            dispatch(setFriendlyUrl(params.friendlyUrl));
            propertyData(response.data);
          })
          .catch(() => {
            navigate('/error');
          });
      } else {
        propertyData(parameters.get('property'));
      }
    }
  }, [session]);

  useEffect(() => {
    if (menu.item === MENU_ITEMS.ROOMS) {
      HTMLHelper.scrollDown(roomsSection);
    }
    if (menu.item === MENU_ITEMS.CONTACT) {
      HTMLHelper.scrollDown(contactSection);
    }
    dispatch(
      selectItem({
        item: null,
      }),
    );
  }, [menu.item]);

  const onGiftCardMenuSelected = (value) => {
    if (value != undefined) {
      setShowGiftCard(value);
    } else {
      setShowGiftCard(!showGiftCard);
    }
  };

  return (
    <BookingEngineLayout showCustomTop={true}>
      {loading && (
        <div className="dimmer">
          <Loader id="full-screen-loader" size="big" indeterminate>
            Loading page...
          </Loader>
        </div>
      )}
      {property?.id && (
        <>
          <div className={classes.root}>
            {<Top showMenu={true} onGiftCardMenuSelected={onGiftCardMenuSelected}></Top>}
            <div style={heroImageStyle}>
              {property.tagLine !== null && (
                <div>
                  <div className={classes.backTop}></div>
                  <div className="top-back-info">
                    <div className="tag-line">{property.tagLine}</div>
                    <ul className="highlight-container">
                      {property != null &&
                        property.highlightList != null &&
                        property.highlightList.length > 0 &&
                        property.highlightList.map((highlight, idx) => (
                          <li key={idx}>
                            {' '}
                            {idx != 0 ? ',' : ''} {highlight.title}&nbsp;
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>

            <PropertySection property={property}></PropertySection>
            <div>
              <RoomsSection property={property}></RoomsSection>
            </div>
            <div ref={roomsSection} className="room-type-section">
              <RoomTypesSection
                property={property}
                handleItemSelected={handleItemSelected}
              ></RoomTypesSection>
            </div>

            <div className={classes.back} ref={contactSection}>
              {property.contact != null && <InfoPanel property={property}></InfoPanel>}
            </div>

            <Footer property={property}></Footer>
          </div>
          <Search prop={property} handleItemSelected={handleItemSelected}></Search>
          <About property={property}></About>
          <Policies property={property}></Policies>
          <GiftCard
            property={property}
            show={showGiftCard}
            onCloseGiftCardPanel={onGiftCardMenuSelected}
          ></GiftCard>
          <MenuResponsive
            property={property}
            onGiftCardMenuSelected={() => setShowGiftCard(true)}
          ></MenuResponsive>
          <RoomTypePreviewPanel
            item={itemForPreview}
            show={showPreview}
            handleOnClickImageContainer={onClickImageContainer}
          ></RoomTypePreviewPanel>
        </>
      )}
    </BookingEngineLayout>
  );
};

export default React.memo(PropertyView);
