import React, {useState, useEffect} from 'react';
import { createUseStyles } from 'react-jss';
import { menuSelector } from '@/store/selector';
import { MENU_ITEMS } from 'utils/constants';
import { Container} from 'semantic-ui-react';

const useStyles = createUseStyles(
  {
    about: {
      paddingLeft: '25px'
    },
    aboutPanel: {
      position: 'fixed',
      width: '50% !important',
      height: '100% !important',
      backgroundColor: '#fff',
      right: '0',
      top: '70px',
      transform: 'translateX(138%)',
      transition: 'transform .75s cubic-bezier(.23,1,.32,1)',
      zIndex: 9,
    },
    aboutPanelActive: {
      transform: 'translateX(0) !important',
    },
    address: {
      marginTop: '8px',
      paddingLeft: '5px',
      fontSize: '12px'
    },
    addressContent: {
      display: 'flex',
      paddingLeft: '25px'
    }
  },
  { name: 'About' },
);
const About = ({ property }) => {
   const classes = useStyles();
   const menu = menuSelector();
   const [show, setShow] = useState({ show: false });

  const bodyScroll = () => {
    setShow(false);
  };
  
  useEffect(() => {
    if (menu.item === MENU_ITEMS.ABOUT){
      setShow(true);
    }
  }, [menu.item]);

  useEffect(() => {
    if (!menu.showAbout){
      setShow(false);
    }
  }, [menu.showAbout]);
    return (
    <Container className={ show === true ? [classes.aboutPanel, classes.aboutPanelActive] : classes.aboutPanel} onMouseLeave={bodyScroll}>
      <div className={classes.about} dangerouslySetInnerHTML={{ __html: property.companyAbout }}></div>
      <div className={classes.addressContent}>
        <div><img src={property.companyLogo}></img></div>
        <div className={classes.address}>{property.companyInfo}</div>
      </div>  
    </Container>
  );
};
export default React.memo(About);
