// import useAxios from 'axios-hooks';
import axios from 'axios';
import { store } from '@/store/store';

// console.log(store);

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: '/api/',
};

// Create axios instance with default params
const axiosInstance = axios.create(axiosParams);

axiosInstance.interceptors.request.use(
  async (config) => {
    if (config?.baseURL.includes('openweathermap')) {
      return config;
    }
    const token = store.getState().session.token;
    if (token) {
      config.headers['Bookingengine-Token'] = token;
    }

    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => Promise.reject(error),
);

// Main api function
const api = (axios) => {
  return {
    get: function (url, config) {
      //const [{ data: getData, loading: getLoading, error: getError }] =
      //return useAxios(url);
      if (config) {
        return axios.get(url, config);
      } else {
        return axios.get(url);
      }
    },
    /*delete: function (url, config) {
      return axios.delete(url);
    },*/
    post: (url, body) => {
      return axios.post(url, body);
      /*return useAxios(
        {
          url: '/api/' + url,
          method: 'POST',
        },
        { manual: true },
      );*/
    },
    /*patch: function (url, body, config) {
      return axios.patch(url, body, config);
    },
    put: function (url, body, config) {
      return axios.put(url, body, config);
    },*/
  };
};
export default api(axiosInstance);
