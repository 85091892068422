import React from 'react';
import 'flatpickr/dist/themes/airbnb.css';
import Flatpickr from 'react-flatpickr';
import Moment from 'moment';
//import useTraceUpdate from '@/hooks/useTraceUpdate';

const WaveCalendar = ({ availability, roomType, rates, onDateChanged }) => {
  //useTraceUpdate({ availability, roomType, rates, onDateChanged });
  return (
    <Flatpickr
      options={{
        inline: true,
        mode: 'range',
        minDate: 'today',
        enable: availability,
      }}
      onChange={(selectedDates) => {
        if (selectedDates[1]) {
          // fix selected check-out date disable
          let selected = document.querySelectorAll('.flatpickr-day.selected');
          if (selected.length == 1) {
            let nextSibling = selected[0].nextSibling;
            while (nextSibling) {
              //if (!Func.hasClass(nextSibling, 'disabled')) {
              if (!nextSibling.classList.contains('flatpickr-disabled')) {
                // reset nextSibling
                nextSibling = nextSibling.nextSibling;
              } else {
                // has disabled
                nextSibling.classList.remove('flatpickr-disabled');
                nextSibling.className += ' endRange notAllowed';
                break;
              }
            }
          }
        } else {
          // remove next disabled date
          let selected = document.querySelectorAll('.flatpickr-day.selected');
          if (selected.length > 0) {
            let nextSibling = selected[0].nextSibling;
            while (nextSibling) {
              //if (!Func.hasClass(nextSibling, 'disabled')) {
              if (!nextSibling.classList.contains('flatpickr-disabled')) {
                // reset nextSibling
                nextSibling = nextSibling.nextSibling;
              } else {
                // has disabled
                nextSibling.classList.remove('flatpickr-disabled');
                break;
              }
            }
          }
        }
        if (selectedDates?.length > 1) {
          onDateChanged([selectedDates[0], selectedDates[1]]);
          //console.log(onDateChanged);
        }
      }}
      onDayCreate={(dObj, dStr, fp, dayElem) => {
        if (roomType != null) {
          if (rates[Moment(dayElem.dateObj).format('YYYY-MM-DD')] != null) {
            dayElem.innerHTML = "<span class='calendar-date'>" + dayElem.innerHTML + '</span>';
            dayElem.innerHTML +=
              "<span class='rate'>$" +
              rates[Moment(dayElem.dateObj).format('YYYY-MM-DD')] +
              '</span>';
          }
        }
        /* WP-1528 - Enable checkout date even if it's disabled */
        if (
          dObj?.length > 1 &&
          Moment(dayElem.dateObj).format('YYYY-MM-DD') == Moment(dObj[1]).format('YYYY-MM-DD')
        ) {
          dayElem.classList.add('selected');
        }
      }}
    />
  );
};

export default React.memo(WaveCalendar);
