import React, {useState, useEffect} from 'react';
import { createUseStyles } from 'react-jss';
import { menuSelector } from '@/store/selector';
import { MENU_ITEMS } from 'utils/constants';

const useStyles = createUseStyles(
  {
    about: {
      paddingLeft: '25px'
    },
    aboutPanel: {
      position: 'fixed',
      width: '50% !important',
      height: '100% !important',
      backgroundColor: '#fff',
      right: '0',
      top: '70px',
      transform: 'translateX(138%)',
      transition: 'transform .75s cubic-bezier(.23,1,.32,1)',
      zIndex: 9,
    },
    aboutPanelActive: {
      composes: '$aboutPanel',
      transform: 'translateX(0) !important',
    },
    address: {
      marginTop: '8px',
      paddingLeft: '5px',
      fontSize: '12px'
    },
    addressContent: {
      display: 'flex',
      paddingLeft: '25px'
    },
    [`@media (max-width: 1200px)`]: {
      aboutPanel: {
        zIndex: '9999999 !important',
        width: '100% !important',
        height: '100% !important',
        top: '0 !important'
      },
    },
  },
  { name: 'About' },
);
const About = ({ property }) => {
   const classes = useStyles();
   const menu = menuSelector();
   const [show, setShow] = useState({ show: false });

  const bodyScroll = () => {
    setShow(false);
  };
  
  useEffect(() => {
    if (menu.item === MENU_ITEMS.POLICIES){
      setShow(true);
    }
  }, [menu.item]);

  useEffect(() => {
    if (!menu.showPolicies){
      setShow(false);
    }
  }, [menu.showPolicies]);
    return (
    <div className={ show === true ? [classes.aboutPanelActive] : classes.aboutPanel} onMouseLeave={bodyScroll}>
      <div className='policies-content'>
      <a onClick={bodyScroll} className='policies-back'><i className='arrow left icon'></i>Back</a>
      {property.propertyPoliciesData.general !== null && (
        <div>
          <div className='policies-title'>General Policy</div>
          <div className={classes.about} dangerouslySetInnerHTML={{ __html: property.propertyPoliciesData.general }}></div>
        </div>        
      )}

      {property.propertyPoliciesData.cancellation !== null && (
        <div>
          <div className='policies-title'>Cancellation Policy</div>
          <div className={classes.about} dangerouslySetInnerHTML={{ __html: property.propertyPoliciesData.cancellation }}></div>
        </div>        
      )}

      {property.propertyPoliciesData.guarantee !== null && (
        <div>
          <div className='policies-title'>Guarantee Policy</div>
          <div className={classes.about} dangerouslySetInnerHTML={{ __html: property.propertyPoliciesData.guarantee }}></div>
        </div>        
      )}
      
      {property.propertyPoliciesData.amenities !== null && (
        <div>
          <div className='policies-title'>Amenities</div>
          <div className={classes.about} dangerouslySetInnerHTML={{ __html: property.propertyPoliciesData.amenities }}></div>
        </div>        
      )}
      </div>
    </div>
  );
};
export default React.memo(About);
