import React from 'react';
import { createUseStyles } from 'react-jss';
import classnames from 'classnames';
import Colors from '@/constants/colors';
import rainImage from '@/assets/images/weather_rain.svg';
import cloudsImage from '@/assets/images/weather_clouds.svg';
import overcastImage from '@/assets/images/weather_overcast.svg';
import snowImage from '@/assets/images/weather_snow.svg';
import sunnyImage from '@/assets/images/weather_sunny.svg';

const useStyles = createUseStyles(
  {
    root: {
      cursor: 'pointer',
      backgroundColor: Colors.whiteOne,
      height: '70px',
      left: '0',
      position: 'relative',
      display: 'inline-block',
      top: 0,
      width: '150px',
      opacity: 0,
      transition: 'all .4s ease-in-out',
      '&.open': {
        opacity: 1,
      },
    },
    date: {
      color: Colors.blackOne,
      fontSize: '1rem',
      left: '15px',
      lineHeight: 'normal',
      margin: 0,
      position: 'absolute',
      top: '10px',
    },
    imageWrapper: {
      lineHeight: '74px',
      textAlign: 'center',
    },
    icon: {
      backgroundPosition: 'center right',
      backgroundRepeat: 'no-repeat',
      display: 'inline-block',
      height: '32px',
      margin: '0 10px 0 0',
      verticalAlign: 'middle',
      width: '32px',
      backgroundSize: '30px 26px',
    },
    text: {
      color: Colors.blackOne,
      display: 'inline-block',
      fontSize: '1rem',
      lineHeight: '32px',
      margin: 0,
      verticalAlign: 'middle',
    },
    rain: {
      backgroundImage: `url(${rainImage})`,
      //-webkit-background-size: 30px 26px;
    },
    clouds: {
      backgroundImage: `url(${cloudsImage})`,
      //-webkit-background-size: 30px 26px;
    },
    overcast: {
      backgroundImage: `url(${overcastImage})`,
      //-webkit-background-size: 30px 26px;
    },
    snow: {
      backgroundImage: `url(${snowImage})`,
      //-webkit-background-size: 30px 26px;
    },
    sunny: {
      backgroundImage: `url(${sunnyImage})`,
      //-webkit-background-size: 30px 26px;
    },
  },
  { name: 'WeatherItem' },
);
const WeatherItem = ({ className, dateText, degreeText, image }) => {
  const classes = useStyles();
  return (
    <div className={classnames(classes.root, className)}>
      <p className={classes.date}>
        <span>{dateText}</span>
      </p>
      <div className={classes.imageWrapper}>
        <div className={classnames(classes.icon, classes[image])}></div>
        <p className={classes.text}>
          {degreeText}
          <span>°</span>
        </p>
      </div>
    </div>
  );
};
export default React.memo(WeatherItem);
