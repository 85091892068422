const Colors = {
  greenOne: '#00a633',
  grayOne: '#cecece',
  grayTwo: '#E5E5E5',
  grayThree: '#747474',
  grayFour: '#cccccc',
  redOne: '#F96D6D',
  redTwo: '#d0011b',
  whiteOne: '#FFFFFF',
  blackOne: '#979797',
  blackTwo: '#000000',
  blueOne: '#646c72',
  blueTwo: '#0191d0',
  transparent: '#f3fcfd',
  transparentTwo: 'transparent',
};

export default Colors;
