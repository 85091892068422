import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { cartSelector } from '@/store/selector';

const useSuccessViewEmptyConfirmation = () => {
  const cart = cartSelector();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      location?.pathname === '/checkout/success' &&
      !cart?.reservationConfirmation.reservationNumber
    ) {
      navigate('/error', { state: cart });
    }

    return () => {
      // Cleanup function
    };
  });
};

export default useSuccessViewEmptyConfirmation;
