import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(
  {
    root: {
      display: 'block',
      // font-family: "Work-Sans", sans-serif,
      fontSize: '1rem',
      lineHeight: 'normal',
      margin: '20px 0 0',
      position: 'relative',
      '& > span': {
        display: 'inline-block',
        position: 'absolute',
        right: 0,
        top: 0,
      },
    },
  },
  { name: 'CartItemLine' },
);

const CartItemLine = ({ title, price, className = undefined }) => {
  const classes = useStyles();

  return (
    <p className={className ? [classes.root, className].join(' ') : classes.root}>
      {title} <span>{price.toFixed(2)}</span>
    </p>
  );
};

export default React.memo(CartItemLine);
