import { round } from 'lodash';

const processWeatherData = (data) => {
  let list = data.list;
  let weatherData = [];
  let days = 6;
  let storedDay = undefined;
  list.forEach((dateInformation) => {
    let date = dateInformation.dt_txt.split('-');
    let mont = parseInt(date[1]);
    let currentDay = date[2].split(' ')[0];
    if (storedDay != currentDay && days > 0) {
      storedDay = currentDay;
      let weatherDataItem = {};
      weatherDataItem.dateInformation = mont + '/' + currentDay;
      weatherDataItem.temp = round(dateInformation.main.temp);
      weatherDataItem.icon = dateInformation.weather[0].main.toLowerCase();
      if (weatherDataItem.icon == 'clear') {
        weatherDataItem.icon = 'sunny';
      }
      if (dateInformation.weather[0].description.indexOf('overcast clouds') > 0) {
        weatherDataItem.icon = 'overcast';
      }
      weatherData.push(weatherDataItem);
      days--;
    }
  });
  return weatherData;
};

export default {
  processWeatherData,
};
