import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  arrival: null,
  departure: null,
  people: 2,
  availability: [],
  rates: [],
  property: null,
  roomType: null,
  windowSize: null,
  resultPanelOpen: false
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    submitSearch: (state, data) => {
      state.arrival = data.payload.arrival;
      state.departure = data.payload.departure;
      state.people = data.payload.people;
    },
    setMainProperty: (state, data) => {
      state.property = data.payload.property;
    },
    setRoomType: (state, data) => {
      state.roomType = data.payload.roomType;
    },
    setAvailability: (state, data) => {
      state.availability = data.payload.availability;
    },
    setRates: (state, data) => {
      state.rates = data.payload.rates;
    },
    setWindowSize: (state, data) => {
      state.windowSize = data.payload.windowSize;
    },
    setResultPanelOpen: (state, data) => {
      state.resultPanelOpen = data.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const { submitSearch } = searchSlice.actions;
export const { setResults } = searchSlice.actions;
export const { setMainProperty } = searchSlice.actions;
export const { setRoomType } = searchSlice.actions;
export const { setAvailability } = searchSlice.actions;
export const { setRates } = searchSlice.actions;
export const { setWindowSize } = searchSlice.actions;
export const { setResultPanelOpen } = searchSlice.actions;

export default searchSlice.reducer;
