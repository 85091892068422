const countryList = [
  {
    name: 'Afghanistan',
    alpha2Code: 'AF',
    alpha3Code: 'AFG',
    independent: false,
  },
  {
    name: 'Åland Islands',
    alpha2Code: 'AX',
    alpha3Code: 'ALA',
    independent: false,
  },
  {
    name: 'Albania',
    alpha2Code: 'AL',
    alpha3Code: 'ALB',
    independent: false,
  },
  {
    name: 'Algeria',
    alpha2Code: 'DZ',
    alpha3Code: 'DZA',
    independent: false,
  },
  {
    name: 'American Samoa',
    alpha2Code: 'AS',
    alpha3Code: 'ASM',
    independent: false,
  },
  {
    name: 'Andorra',
    alpha2Code: 'AD',
    alpha3Code: 'AND',
    independent: false,
  },
  {
    name: 'Angola',
    alpha2Code: 'AO',
    alpha3Code: 'AGO',
    independent: false,
  },
  {
    name: 'Anguilla',
    alpha2Code: 'AI',
    alpha3Code: 'AIA',
    independent: false,
  },
  {
    name: 'Antarctica',
    alpha2Code: 'AQ',
    alpha3Code: 'ATA',
    independent: false,
  },
  {
    name: 'Antigua and Barbuda',
    alpha2Code: 'AG',
    alpha3Code: 'ATG',
    independent: false,
  },
  {
    name: 'Argentina',
    alpha2Code: 'AR',
    alpha3Code: 'ARG',
    independent: false,
  },
  {
    name: 'Armenia',
    alpha2Code: 'AM',
    alpha3Code: 'ARM',
    independent: false,
  },
  {
    name: 'Aruba',
    alpha2Code: 'AW',
    alpha3Code: 'ABW',
    independent: false,
  },
  {
    name: 'Australia',
    alpha2Code: 'AU',
    alpha3Code: 'AUS',
    independent: false,
  },
  {
    name: 'Austria',
    alpha2Code: 'AT',
    alpha3Code: 'AUT',
    independent: false,
  },
  {
    name: 'Azerbaijan',
    alpha2Code: 'AZ',
    alpha3Code: 'AZE',
    independent: false,
  },
  {
    name: 'Bahamas',
    alpha2Code: 'BS',
    alpha3Code: 'BHS',
    independent: false,
  },
  {
    name: 'Bahrain',
    alpha2Code: 'BH',
    alpha3Code: 'BHR',
    independent: false,
  },
  {
    name: 'Bangladesh',
    alpha2Code: 'BD',
    alpha3Code: 'BGD',
    independent: false,
  },
  {
    name: 'Barbados',
    alpha2Code: 'BB',
    alpha3Code: 'BRB',
    independent: false,
  },
  {
    name: 'Belarus',
    alpha2Code: 'BY',
    alpha3Code: 'BLR',
    independent: false,
  },
  {
    name: 'Belgium',
    alpha2Code: 'BE',
    alpha3Code: 'BEL',
    independent: false,
  },
  {
    name: 'Belize',
    alpha2Code: 'BZ',
    alpha3Code: 'BLZ',
    independent: false,
  },
  {
    name: 'Benin',
    alpha2Code: 'BJ',
    alpha3Code: 'BEN',
    independent: false,
  },
  {
    name: 'Bermuda',
    alpha2Code: 'BM',
    alpha3Code: 'BMU',
    independent: false,
  },
  {
    name: 'Bhutan',
    alpha2Code: 'BT',
    alpha3Code: 'BTN',
    independent: false,
  },
  {
    name: 'Bolivia (Plurinational State of)',
    alpha2Code: 'BO',
    alpha3Code: 'BOL',
    independent: false,
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    alpha2Code: 'BQ',
    alpha3Code: 'BES',
    independent: false,
  },
  {
    name: 'Bosnia and Herzegovina',
    alpha2Code: 'BA',
    alpha3Code: 'BIH',
    independent: false,
  },
  {
    name: 'Botswana',
    alpha2Code: 'BW',
    alpha3Code: 'BWA',
    independent: false,
  },
  {
    name: 'Bouvet Island',
    alpha2Code: 'BV',
    alpha3Code: 'BVT',
    independent: false,
  },
  {
    name: 'Brazil',
    alpha2Code: 'BR',
    alpha3Code: 'BRA',
    independent: false,
  },
  {
    name: 'British Indian Ocean Territory',
    alpha2Code: 'IO',
    alpha3Code: 'IOT',
    independent: false,
  },
  {
    name: 'United States Minor Outlying Islands',
    alpha2Code: 'UM',
    alpha3Code: 'UMI',
    independent: false,
  },
  {
    name: 'Virgin Islands (British)',
    alpha2Code: 'VG',
    alpha3Code: 'VGB',
    independent: false,
  },
  {
    name: 'Virgin Islands (U.S.)',
    alpha2Code: 'VI',
    alpha3Code: 'VIR',
    independent: false,
  },
  {
    name: 'Brunei Darussalam',
    alpha2Code: 'BN',
    alpha3Code: 'BRN',
    independent: false,
  },
  {
    name: 'Bulgaria',
    alpha2Code: 'BG',
    alpha3Code: 'BGR',
    independent: false,
  },
  {
    name: 'Burkina Faso',
    alpha2Code: 'BF',
    alpha3Code: 'BFA',
    independent: false,
  },
  {
    name: 'Burundi',
    alpha2Code: 'BI',
    alpha3Code: 'BDI',
    independent: false,
  },
  {
    name: 'Cambodia',
    alpha2Code: 'KH',
    alpha3Code: 'KHM',
    independent: false,
  },
  {
    name: 'Cameroon',
    alpha2Code: 'CM',
    alpha3Code: 'CMR',
    independent: false,
  },
  {
    name: 'Canada',
    alpha2Code: 'CA',
    alpha3Code: 'CAN',
    independent: false,
  },
  {
    name: 'Cabo Verde',
    alpha2Code: 'CV',
    alpha3Code: 'CPV',
    independent: false,
  },
  {
    name: 'Cayman Islands',
    alpha2Code: 'KY',
    alpha3Code: 'CYM',
    independent: false,
  },
  {
    name: 'Central African Republic',
    alpha2Code: 'CF',
    alpha3Code: 'CAF',
    independent: false,
  },
  {
    name: 'Chad',
    alpha2Code: 'TD',
    alpha3Code: 'TCD',
    independent: false,
  },
  {
    name: 'Chile',
    alpha2Code: 'CL',
    alpha3Code: 'CHL',
    independent: false,
  },
  {
    name: 'China',
    alpha2Code: 'CN',
    alpha3Code: 'CHN',
    independent: false,
  },
  {
    name: 'Christmas Island',
    alpha2Code: 'CX',
    alpha3Code: 'CXR',
    independent: false,
  },
  {
    name: 'Cocos (Keeling) Islands',
    alpha2Code: 'CC',
    alpha3Code: 'CCK',
    independent: false,
  },
  {
    name: 'Colombia',
    alpha2Code: 'CO',
    alpha3Code: 'COL',
    independent: false,
  },
  {
    name: 'Comoros',
    alpha2Code: 'KM',
    alpha3Code: 'COM',
    independent: false,
  },
  {
    name: 'Congo',
    alpha2Code: 'CG',
    alpha3Code: 'COG',
    independent: false,
  },
  {
    name: 'Congo (Democratic Republic of the)',
    alpha2Code: 'CD',
    alpha3Code: 'COD',
    independent: false,
  },
  {
    name: 'Cook Islands',
    alpha2Code: 'CK',
    alpha3Code: 'COK',
    independent: false,
  },
  {
    name: 'Costa Rica',
    alpha2Code: 'CR',
    alpha3Code: 'CRI',
    independent: false,
  },
  {
    name: 'Croatia',
    alpha2Code: 'HR',
    alpha3Code: 'HRV',
    independent: false,
  },
  {
    name: 'Cuba',
    alpha2Code: 'CU',
    alpha3Code: 'CUB',
    independent: false,
  },
  {
    name: 'Curaçao',
    alpha2Code: 'CW',
    alpha3Code: 'CUW',
    independent: false,
  },
  {
    name: 'Cyprus',
    alpha2Code: 'CY',
    alpha3Code: 'CYP',
    independent: false,
  },
  {
    name: 'Czech Republic',
    alpha2Code: 'CZ',
    alpha3Code: 'CZE',
    independent: false,
  },
  {
    name: 'Denmark',
    alpha2Code: 'DK',
    alpha3Code: 'DNK',
    independent: false,
  },
  {
    name: 'Djibouti',
    alpha2Code: 'DJ',
    alpha3Code: 'DJI',
    independent: false,
  },
  {
    name: 'Dominica',
    alpha2Code: 'DM',
    alpha3Code: 'DMA',
    independent: false,
  },
  {
    name: 'Dominican Republic',
    alpha2Code: 'DO',
    alpha3Code: 'DOM',
    independent: false,
  },
  {
    name: 'Ecuador',
    alpha2Code: 'EC',
    alpha3Code: 'ECU',
    independent: false,
  },
  {
    name: 'Egypt',
    alpha2Code: 'EG',
    alpha3Code: 'EGY',
    independent: false,
  },
  {
    name: 'El Salvador',
    alpha2Code: 'SV',
    alpha3Code: 'SLV',
    independent: false,
  },
  {
    name: 'Equatorial Guinea',
    alpha2Code: 'GQ',
    alpha3Code: 'GNQ',
    independent: false,
  },
  {
    name: 'Eritrea',
    alpha2Code: 'ER',
    alpha3Code: 'ERI',
    independent: false,
  },
  {
    name: 'Estonia',
    alpha2Code: 'EE',
    alpha3Code: 'EST',
    independent: false,
  },
  {
    name: 'Ethiopia',
    alpha2Code: 'ET',
    alpha3Code: 'ETH',
    independent: false,
  },
  {
    name: 'Falkland Islands (Malvinas)',
    alpha2Code: 'FK',
    alpha3Code: 'FLK',
    independent: false,
  },
  {
    name: 'Faroe Islands',
    alpha2Code: 'FO',
    alpha3Code: 'FRO',
    independent: false,
  },
  {
    name: 'Fiji',
    alpha2Code: 'FJ',
    alpha3Code: 'FJI',
    independent: false,
  },
  {
    name: 'Finland',
    alpha2Code: 'FI',
    alpha3Code: 'FIN',
    independent: false,
  },
  {
    name: 'France',
    alpha2Code: 'FR',
    alpha3Code: 'FRA',
    independent: false,
  },
  {
    name: 'French Guiana',
    alpha2Code: 'GF',
    alpha3Code: 'GUF',
    independent: false,
  },
  {
    name: 'French Polynesia',
    alpha2Code: 'PF',
    alpha3Code: 'PYF',
    independent: false,
  },
  {
    name: 'French Southern Territories',
    alpha2Code: 'TF',
    alpha3Code: 'ATF',
    independent: false,
  },
  {
    name: 'Gabon',
    alpha2Code: 'GA',
    alpha3Code: 'GAB',
    independent: false,
  },
  {
    name: 'Gambia',
    alpha2Code: 'GM',
    alpha3Code: 'GMB',
    independent: false,
  },
  {
    name: 'Georgia',
    alpha2Code: 'GE',
    alpha3Code: 'GEO',
    independent: false,
  },
  {
    name: 'Germany',
    alpha2Code: 'DE',
    alpha3Code: 'DEU',
    independent: false,
  },
  {
    name: 'Ghana',
    alpha2Code: 'GH',
    alpha3Code: 'GHA',
    independent: false,
  },
  {
    name: 'Gibraltar',
    alpha2Code: 'GI',
    alpha3Code: 'GIB',
    independent: false,
  },
  {
    name: 'Greece',
    alpha2Code: 'GR',
    alpha3Code: 'GRC',
    independent: false,
  },
  {
    name: 'Greenland',
    alpha2Code: 'GL',
    alpha3Code: 'GRL',
    independent: false,
  },
  {
    name: 'Grenada',
    alpha2Code: 'GD',
    alpha3Code: 'GRD',
    independent: false,
  },
  {
    name: 'Guadeloupe',
    alpha2Code: 'GP',
    alpha3Code: 'GLP',
    independent: false,
  },
  {
    name: 'Guam',
    alpha2Code: 'GU',
    alpha3Code: 'GUM',
    independent: false,
  },
  {
    name: 'Guatemala',
    alpha2Code: 'GT',
    alpha3Code: 'GTM',
    independent: false,
  },
  {
    name: 'Guernsey',
    alpha2Code: 'GG',
    alpha3Code: 'GGY',
    independent: false,
  },
  {
    name: 'Guinea',
    alpha2Code: 'GN',
    alpha3Code: 'GIN',
    independent: false,
  },
  {
    name: 'Guinea-Bissau',
    alpha2Code: 'GW',
    alpha3Code: 'GNB',
    independent: false,
  },
  {
    name: 'Guyana',
    alpha2Code: 'GY',
    alpha3Code: 'GUY',
    independent: false,
  },
  {
    name: 'Haiti',
    alpha2Code: 'HT',
    alpha3Code: 'HTI',
    independent: false,
  },
  {
    name: 'Heard Island and McDonald Islands',
    alpha2Code: 'HM',
    alpha3Code: 'HMD',
    independent: false,
  },
  {
    name: 'Vatican City',
    alpha2Code: 'VA',
    alpha3Code: 'VAT',
    independent: false,
  },
  {
    name: 'Honduras',
    alpha2Code: 'HN',
    alpha3Code: 'HND',
    independent: false,
  },
  {
    name: 'Hungary',
    alpha2Code: 'HU',
    alpha3Code: 'HUN',
    independent: false,
  },
  {
    name: 'Hong Kong',
    alpha2Code: 'HK',
    alpha3Code: 'HKG',
    independent: false,
  },
  {
    name: 'Iceland',
    alpha2Code: 'IS',
    alpha3Code: 'ISL',
    independent: false,
  },
  {
    name: 'India',
    alpha2Code: 'IN',
    alpha3Code: 'IND',
    independent: false,
  },
  {
    name: 'Indonesia',
    alpha2Code: 'ID',
    alpha3Code: 'IDN',
    independent: false,
  },
  {
    name: 'Ivory Coast',
    alpha2Code: 'CI',
    alpha3Code: 'CIV',
    independent: false,
  },
  {
    name: 'Iran (Islamic Republic of)',
    alpha2Code: 'IR',
    alpha3Code: 'IRN',
    independent: false,
  },
  {
    name: 'Iraq',
    alpha2Code: 'IQ',
    alpha3Code: 'IRQ',
    independent: false,
  },
  {
    name: 'Ireland',
    alpha2Code: 'IE',
    alpha3Code: 'IRL',
    independent: false,
  },
  {
    name: 'Isle of Man',
    alpha2Code: 'IM',
    alpha3Code: 'IMN',
    independent: false,
  },
  {
    name: 'Israel',
    alpha2Code: 'IL',
    alpha3Code: 'ISR',
    independent: false,
  },
  {
    name: 'Italy',
    alpha2Code: 'IT',
    alpha3Code: 'ITA',
    independent: false,
  },
  {
    name: 'Jamaica',
    alpha2Code: 'JM',
    alpha3Code: 'JAM',
    independent: false,
  },
  {
    name: 'Japan',
    alpha2Code: 'JP',
    alpha3Code: 'JPN',
    independent: false,
  },
  {
    name: 'Jersey',
    alpha2Code: 'JE',
    alpha3Code: 'JEY',
    independent: false,
  },
  {
    name: 'Jordan',
    alpha2Code: 'JO',
    alpha3Code: 'JOR',
    independent: false,
  },
  {
    name: 'Kazakhstan',
    alpha2Code: 'KZ',
    alpha3Code: 'KAZ',
    independent: false,
  },
  {
    name: 'Kenya',
    alpha2Code: 'KE',
    alpha3Code: 'KEN',
    independent: false,
  },
  {
    name: 'Kiribati',
    alpha2Code: 'KI',
    alpha3Code: 'KIR',
    independent: false,
  },
  {
    name: 'Kuwait',
    alpha2Code: 'KW',
    alpha3Code: 'KWT',
    independent: false,
  },
  {
    name: 'Kyrgyzstan',
    alpha2Code: 'KG',
    alpha3Code: 'KGZ',
    independent: false,
  },
  {
    name: "Lao People's Democratic Republic",
    alpha2Code: 'LA',
    alpha3Code: 'LAO',
    independent: false,
  },
  {
    name: 'Latvia',
    alpha2Code: 'LV',
    alpha3Code: 'LVA',
    independent: false,
  },
  {
    name: 'Lebanon',
    alpha2Code: 'LB',
    alpha3Code: 'LBN',
    independent: false,
  },
  {
    name: 'Lesotho',
    alpha2Code: 'LS',
    alpha3Code: 'LSO',
    independent: false,
  },
  {
    name: 'Liberia',
    alpha2Code: 'LR',
    alpha3Code: 'LBR',
    independent: false,
  },
  {
    name: 'Libya',
    alpha2Code: 'LY',
    alpha3Code: 'LBY',
    independent: false,
  },
  {
    name: 'Liechtenstein',
    alpha2Code: 'LI',
    alpha3Code: 'LIE',
    independent: false,
  },
  {
    name: 'Lithuania',
    alpha2Code: 'LT',
    alpha3Code: 'LTU',
    independent: false,
  },
  {
    name: 'Luxembourg',
    alpha2Code: 'LU',
    alpha3Code: 'LUX',
    independent: false,
  },
  {
    name: 'Macao',
    alpha2Code: 'MO',
    alpha3Code: 'MAC',
    independent: false,
  },
  {
    name: 'North Macedonia',
    alpha2Code: 'MK',
    alpha3Code: 'MKD',
    independent: false,
  },
  {
    name: 'Madagascar',
    alpha2Code: 'MG',
    alpha3Code: 'MDG',
    independent: false,
  },
  {
    name: 'Malawi',
    alpha2Code: 'MW',
    alpha3Code: 'MWI',
    independent: false,
  },
  {
    name: 'Malaysia',
    alpha2Code: 'MY',
    alpha3Code: 'MYS',
    independent: false,
  },
  {
    name: 'Maldives',
    alpha2Code: 'MV',
    alpha3Code: 'MDV',
    independent: false,
  },
  {
    name: 'Mali',
    alpha2Code: 'ML',
    alpha3Code: 'MLI',
    independent: false,
  },
  {
    name: 'Malta',
    alpha2Code: 'MT',
    alpha3Code: 'MLT',
    independent: false,
  },
  {
    name: 'Marshall Islands',
    alpha2Code: 'MH',
    alpha3Code: 'MHL',
    independent: false,
  },
  {
    name: 'Martinique',
    alpha2Code: 'MQ',
    alpha3Code: 'MTQ',
    independent: false,
  },
  {
    name: 'Mauritania',
    alpha2Code: 'MR',
    alpha3Code: 'MRT',
    independent: false,
  },
  {
    name: 'Mauritius',
    alpha2Code: 'MU',
    alpha3Code: 'MUS',
    independent: false,
  },
  {
    name: 'Mayotte',
    alpha2Code: 'YT',
    alpha3Code: 'MYT',
    independent: false,
  },
  {
    name: 'Mexico',
    alpha2Code: 'MX',
    alpha3Code: 'MEX',
    independent: false,
  },
  {
    name: 'Micronesia (Federated States of)',
    alpha2Code: 'FM',
    alpha3Code: 'FSM',
    independent: false,
  },
  {
    name: 'Moldova (Republic of)',
    alpha2Code: 'MD',
    alpha3Code: 'MDA',
    independent: false,
  },
  {
    name: 'Monaco',
    alpha2Code: 'MC',
    alpha3Code: 'MCO',
    independent: false,
  },
  {
    name: 'Mongolia',
    alpha2Code: 'MN',
    alpha3Code: 'MNG',
    independent: false,
  },
  {
    name: 'Montenegro',
    alpha2Code: 'ME',
    alpha3Code: 'MNE',
    independent: false,
  },
  {
    name: 'Montserrat',
    alpha2Code: 'MS',
    alpha3Code: 'MSR',
    independent: false,
  },
  {
    name: 'Morocco',
    alpha2Code: 'MA',
    alpha3Code: 'MAR',
    independent: false,
  },
  {
    name: 'Mozambique',
    alpha2Code: 'MZ',
    alpha3Code: 'MOZ',
    independent: false,
  },
  {
    name: 'Myanmar',
    alpha2Code: 'MM',
    alpha3Code: 'MMR',
    independent: false,
  },
  {
    name: 'Namibia',
    alpha2Code: 'NA',
    alpha3Code: 'NAM',
    independent: false,
  },
  {
    name: 'Nauru',
    alpha2Code: 'NR',
    alpha3Code: 'NRU',
    independent: false,
  },
  {
    name: 'Nepal',
    alpha2Code: 'NP',
    alpha3Code: 'NPL',
    independent: false,
  },
  {
    name: 'Netherlands',
    alpha2Code: 'NL',
    alpha3Code: 'NLD',
    independent: false,
  },
  {
    name: 'New Caledonia',
    alpha2Code: 'NC',
    alpha3Code: 'NCL',
    independent: false,
  },
  {
    name: 'New Zealand',
    alpha2Code: 'NZ',
    alpha3Code: 'NZL',
    independent: false,
  },
  {
    name: 'Nicaragua',
    alpha2Code: 'NI',
    alpha3Code: 'NIC',
    independent: false,
  },
  {
    name: 'Niger',
    alpha2Code: 'NE',
    alpha3Code: 'NER',
    independent: false,
  },
  {
    name: 'Nigeria',
    alpha2Code: 'NG',
    alpha3Code: 'NGA',
    independent: false,
  },
  {
    name: 'Niue',
    alpha2Code: 'NU',
    alpha3Code: 'NIU',
    independent: false,
  },
  {
    name: 'Norfolk Island',
    alpha2Code: 'NF',
    alpha3Code: 'NFK',
    independent: false,
  },
  {
    name: "Korea (Democratic People's Republic of)",
    alpha2Code: 'KP',
    alpha3Code: 'PRK',
    independent: false,
  },
  {
    name: 'Northern Mariana Islands',
    alpha2Code: 'MP',
    alpha3Code: 'MNP',
    independent: false,
  },
  {
    name: 'Norway',
    alpha2Code: 'NO',
    alpha3Code: 'NOR',
    independent: false,
  },
  {
    name: 'Oman',
    alpha2Code: 'OM',
    alpha3Code: 'OMN',
    independent: false,
  },
  {
    name: 'Pakistan',
    alpha2Code: 'PK',
    alpha3Code: 'PAK',
    independent: false,
  },
  {
    name: 'Palau',
    alpha2Code: 'PW',
    alpha3Code: 'PLW',
    independent: false,
  },
  {
    name: 'Palestine, State of',
    alpha2Code: 'PS',
    alpha3Code: 'PSE',
    independent: false,
  },
  {
    name: 'Panama',
    alpha2Code: 'PA',
    alpha3Code: 'PAN',
    independent: false,
  },
  {
    name: 'Papua New Guinea',
    alpha2Code: 'PG',
    alpha3Code: 'PNG',
    independent: false,
  },
  {
    name: 'Paraguay',
    alpha2Code: 'PY',
    alpha3Code: 'PRY',
    independent: false,
  },
  {
    name: 'Peru',
    alpha2Code: 'PE',
    alpha3Code: 'PER',
    independent: false,
  },
  {
    name: 'Philippines',
    alpha2Code: 'PH',
    alpha3Code: 'PHL',
    independent: false,
  },
  {
    name: 'Pitcairn',
    alpha2Code: 'PN',
    alpha3Code: 'PCN',
    independent: false,
  },
  {
    name: 'Poland',
    alpha2Code: 'PL',
    alpha3Code: 'POL',
    independent: false,
  },
  {
    name: 'Portugal',
    alpha2Code: 'PT',
    alpha3Code: 'PRT',
    independent: false,
  },
  {
    name: 'Puerto Rico',
    alpha2Code: 'PR',
    alpha3Code: 'PRI',
    independent: false,
  },
  {
    name: 'Qatar',
    alpha2Code: 'QA',
    alpha3Code: 'QAT',
    independent: false,
  },
  {
    name: 'Republic of Kosovo',
    alpha2Code: 'XK',
    alpha3Code: 'UNK',
    independent: false,
  },
  {
    name: 'Réunion',
    alpha2Code: 'RE',
    alpha3Code: 'REU',
    independent: false,
  },
  {
    name: 'Romania',
    alpha2Code: 'RO',
    alpha3Code: 'ROU',
    independent: false,
  },
  {
    name: 'Russian Federation',
    alpha2Code: 'RU',
    alpha3Code: 'RUS',
    independent: false,
  },
  {
    name: 'Rwanda',
    alpha2Code: 'RW',
    alpha3Code: 'RWA',
    independent: false,
  },
  {
    name: 'Saint Barthélemy',
    alpha2Code: 'BL',
    alpha3Code: 'BLM',
    independent: false,
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    alpha2Code: 'SH',
    alpha3Code: 'SHN',
    independent: false,
  },
  {
    name: 'Saint Kitts and Nevis',
    alpha2Code: 'KN',
    alpha3Code: 'KNA',
    independent: false,
  },
  {
    name: 'Saint Lucia',
    alpha2Code: 'LC',
    alpha3Code: 'LCA',
    independent: false,
  },
  {
    name: 'Saint Martin (French part)',
    alpha2Code: 'MF',
    alpha3Code: 'MAF',
    independent: false,
  },
  {
    name: 'Saint Pierre and Miquelon',
    alpha2Code: 'PM',
    alpha3Code: 'SPM',
    independent: false,
  },
  {
    name: 'Saint Vincent and the Grenadines',
    alpha2Code: 'VC',
    alpha3Code: 'VCT',
    independent: false,
  },
  {
    name: 'Samoa',
    alpha2Code: 'WS',
    alpha3Code: 'WSM',
    independent: false,
  },
  {
    name: 'San Marino',
    alpha2Code: 'SM',
    alpha3Code: 'SMR',
    independent: false,
  },
  {
    name: 'Sao Tome and Principe',
    alpha2Code: 'ST',
    alpha3Code: 'STP',
    independent: false,
  },
  {
    name: 'Saudi Arabia',
    alpha2Code: 'SA',
    alpha3Code: 'SAU',
    independent: false,
  },
  {
    name: 'Senegal',
    alpha2Code: 'SN',
    alpha3Code: 'SEN',
    independent: false,
  },
  {
    name: 'Serbia',
    alpha2Code: 'RS',
    alpha3Code: 'SRB',
    independent: false,
  },
  {
    name: 'Seychelles',
    alpha2Code: 'SC',
    alpha3Code: 'SYC',
    independent: false,
  },
  {
    name: 'Sierra Leone',
    alpha2Code: 'SL',
    alpha3Code: 'SLE',
    independent: false,
  },
  {
    name: 'Singapore',
    alpha2Code: 'SG',
    alpha3Code: 'SGP',
    independent: false,
  },
  {
    name: 'Sint Maarten (Dutch part)',
    alpha2Code: 'SX',
    alpha3Code: 'SXM',
    independent: false,
  },
  {
    name: 'Slovakia',
    alpha2Code: 'SK',
    alpha3Code: 'SVK',
    independent: false,
  },
  {
    name: 'Slovenia',
    alpha2Code: 'SI',
    alpha3Code: 'SVN',
    independent: false,
  },
  {
    name: 'Solomon Islands',
    alpha2Code: 'SB',
    alpha3Code: 'SLB',
    independent: false,
  },
  {
    name: 'Somalia',
    alpha2Code: 'SO',
    alpha3Code: 'SOM',
    independent: false,
  },
  {
    name: 'South Africa',
    alpha2Code: 'ZA',
    alpha3Code: 'ZAF',
    independent: false,
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    alpha2Code: 'GS',
    alpha3Code: 'SGS',
    independent: false,
  },
  {
    name: 'Korea (Republic of)',
    alpha2Code: 'KR',
    alpha3Code: 'KOR',
    independent: false,
  },
  {
    name: 'Spain',
    alpha2Code: 'ES',
    alpha3Code: 'ESP',
    independent: false,
  },
  {
    name: 'Sri Lanka',
    alpha2Code: 'LK',
    alpha3Code: 'LKA',
    independent: false,
  },
  {
    name: 'Sudan',
    alpha2Code: 'SD',
    alpha3Code: 'SDN',
    independent: false,
  },
  {
    name: 'South Sudan',
    alpha2Code: 'SS',
    alpha3Code: 'SSD',
    independent: false,
  },
  {
    name: 'Suriname',
    alpha2Code: 'SR',
    alpha3Code: 'SUR',
    independent: false,
  },
  {
    name: 'Svalbard and Jan Mayen',
    alpha2Code: 'SJ',
    alpha3Code: 'SJM',
    independent: false,
  },
  {
    name: 'Swaziland',
    alpha2Code: 'SZ',
    alpha3Code: 'SWZ',
    independent: false,
  },
  {
    name: 'Sweden',
    alpha2Code: 'SE',
    alpha3Code: 'SWE',
    independent: false,
  },
  {
    name: 'Switzerland',
    alpha2Code: 'CH',
    alpha3Code: 'CHE',
    independent: false,
  },
  {
    name: 'Syrian Arab Republic',
    alpha2Code: 'SY',
    alpha3Code: 'SYR',
    independent: false,
  },
  {
    name: 'Taiwan',
    alpha2Code: 'TW',
    alpha3Code: 'TWN',
    independent: false,
  },
  {
    name: 'Tajikistan',
    alpha2Code: 'TJ',
    alpha3Code: 'TJK',
    independent: false,
  },
  {
    name: 'Tanzania, United Republic of',
    alpha2Code: 'TZ',
    alpha3Code: 'TZA',
    independent: false,
  },
  {
    name: 'Thailand',
    alpha2Code: 'TH',
    alpha3Code: 'THA',
    independent: false,
  },
  {
    name: 'Timor-Leste',
    alpha2Code: 'TL',
    alpha3Code: 'TLS',
    independent: false,
  },
  {
    name: 'Togo',
    alpha2Code: 'TG',
    alpha3Code: 'TGO',
    independent: false,
  },
  {
    name: 'Tokelau',
    alpha2Code: 'TK',
    alpha3Code: 'TKL',
    independent: false,
  },
  {
    name: 'Tonga',
    alpha2Code: 'TO',
    alpha3Code: 'TON',
    independent: false,
  },
  {
    name: 'Trinidad and Tobago',
    alpha2Code: 'TT',
    alpha3Code: 'TTO',
    independent: false,
  },
  {
    name: 'Tunisia',
    alpha2Code: 'TN',
    alpha3Code: 'TUN',
    independent: false,
  },
  {
    name: 'Turkey',
    alpha2Code: 'TR',
    alpha3Code: 'TUR',
    independent: false,
  },
  {
    name: 'Turkmenistan',
    alpha2Code: 'TM',
    alpha3Code: 'TKM',
    independent: false,
  },
  {
    name: 'Turks and Caicos Islands',
    alpha2Code: 'TC',
    alpha3Code: 'TCA',
    independent: false,
  },
  {
    name: 'Tuvalu',
    alpha2Code: 'TV',
    alpha3Code: 'TUV',
    independent: false,
  },
  {
    name: 'Uganda',
    alpha2Code: 'UG',
    alpha3Code: 'UGA',
    independent: false,
  },
  {
    name: 'Ukraine',
    alpha2Code: 'UA',
    alpha3Code: 'UKR',
    independent: false,
  },
  {
    name: 'United Arab Emirates',
    alpha2Code: 'AE',
    alpha3Code: 'ARE',
    independent: false,
  },
  {
    name: 'United Kingdom of Great Britain and Northern Ireland',
    alpha2Code: 'GB',
    alpha3Code: 'GBR',
    independent: false,
  },
  {
    name: 'United States of America',
    alpha2Code: 'US',
    alpha3Code: 'USA',
    independent: false,
  },
  {
    name: 'Uruguay',
    alpha2Code: 'UY',
    alpha3Code: 'URY',
    independent: false,
  },
  {
    name: 'Uzbekistan',
    alpha2Code: 'UZ',
    alpha3Code: 'UZB',
    independent: false,
  },
  {
    name: 'Vanuatu',
    alpha2Code: 'VU',
    alpha3Code: 'VUT',
    independent: false,
  },
  {
    name: 'Venezuela (Bolivarian Republic of)',
    alpha2Code: 'VE',
    alpha3Code: 'VEN',
    independent: false,
  },
  {
    name: 'Vietnam',
    alpha2Code: 'VN',
    alpha3Code: 'VNM',
    independent: false,
  },
  {
    name: 'Wallis and Futuna',
    alpha2Code: 'WF',
    alpha3Code: 'WLF',
    independent: false,
  },
  {
    name: 'Western Sahara',
    alpha2Code: 'EH',
    alpha3Code: 'ESH',
    independent: false,
  },
  {
    name: 'Yemen',
    alpha2Code: 'YE',
    alpha3Code: 'YEM',
    independent: false,
  },
  {
    name: 'Zambia',
    alpha2Code: 'ZM',
    alpha3Code: 'ZMB',
    independent: false,
  },
  {
    name: 'Zimbabwe',
    alpha2Code: 'ZW',
    alpha3Code: 'ZWE',
    independent: false,
  },
];

const stateList = [
  {
    name: 'Alabama',
    abbreviation: 'AL',
  },
  {
    name: 'Alaska',
    abbreviation: 'AK',
  },
  {
    name: 'American Samoa',
    abbreviation: 'AS',
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ',
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR',
  },
  {
    name: 'California',
    abbreviation: 'CA',
  },
  {
    name: 'Colorado',
    abbreviation: 'CO',
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT',
  },
  {
    name: 'Delaware',
    abbreviation: 'DE',
  },
  {
    name: 'District Of Columbia',
    abbreviation: 'DC',
  },
  {
    name: 'Federated States Of Micronesia',
    abbreviation: 'FM',
  },
  {
    name: 'Florida',
    abbreviation: 'FL',
  },
  {
    name: 'Georgia',
    abbreviation: 'GA',
  },
  {
    name: 'Guam',
    abbreviation: 'GU',
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI',
  },
  {
    name: 'Idaho',
    abbreviation: 'ID',
  },
  {
    name: 'Illinois',
    abbreviation: 'IL',
  },
  {
    name: 'Indiana',
    abbreviation: 'IN',
  },
  {
    name: 'Iowa',
    abbreviation: 'IA',
  },
  {
    name: 'Kansas',
    abbreviation: 'KS',
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY',
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA',
  },
  {
    name: 'Maine',
    abbreviation: 'ME',
  },
  {
    name: 'Marshall Islands',
    abbreviation: 'MH',
  },
  {
    name: 'Maryland',
    abbreviation: 'MD',
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA',
  },
  {
    name: 'Michigan',
    abbreviation: 'MI',
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN',
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS',
  },
  {
    name: 'Missouri',
    abbreviation: 'MO',
  },
  {
    name: 'Montana',
    abbreviation: 'MT',
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE',
  },
  {
    name: 'Nevada',
    abbreviation: 'NV',
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH',
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ',
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM',
  },
  {
    name: 'New York',
    abbreviation: 'NY',
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC',
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND',
  },
  {
    name: 'Northern Mariana Islands',
    abbreviation: 'MP',
  },
  {
    name: 'Ohio',
    abbreviation: 'OH',
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK',
  },
  {
    name: 'Oregon',
    abbreviation: 'OR',
  },
  {
    name: 'Palau',
    abbreviation: 'PW',
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA',
  },
  {
    name: 'Puerto Rico',
    abbreviation: 'PR',
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI',
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC',
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD',
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN',
  },
  {
    name: 'Texas',
    abbreviation: 'TX',
  },
  {
    name: 'Utah',
    abbreviation: 'UT',
  },
  {
    name: 'Vermont',
    abbreviation: 'VT',
  },
  {
    name: 'Virgin Islands',
    abbreviation: 'VI',
  },
  {
    name: 'Virginia',
    abbreviation: 'VA',
  },
  {
    name: 'Washington',
    abbreviation: 'WA',
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV',
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI',
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY',
  },
];

export const listCountry = () => {
  return countryList;
};

export const listState = () => {
  return stateList;
};
