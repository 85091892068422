import React from 'react';
import { createUseStyles } from 'react-jss';
import Colors from '@/constants/colors';
import { Grid, GridColumn } from 'semantic-ui-react';
import './CheckoutPanel.css';

const useStyles = createUseStyles(
  {
    root: {
      height: '100%',
      //left: 0,
      //position: 'fixed',
      //top: 0,
      width: '100%',
      zIndex: 2000,
    },
    content: {
      height: '100%',
      // overflowY: 'auto',
      position: 'relative',
      // -webkitOverflowScrolling: 'touch',
      width: 'calc(100% + 18px)',
    },
    overflowScroll: {
      height: '100%',
      overflowY: 'auto',
      position: 'relative',
      // -webkitOverflowScrolling: 'touch',
      width: 'calc(100% + 18px)',
    },
    checkoutContent: {
      padding: '100px 0 40px',
      width: 'calc(100% - 18px)',
      '& > h2': {
        fontSize: '1.5rem',
        maxWidth: '50%',
        padding: '0 60px',
        width: '100%',
        fontWeight: 500,
        marginBottom: '25px',
      },
    },
    leftContent: {
      maxWidth: '50%',
      padding: '0 60px',
      width: '100%',
    },
    cartContent: {
      // backgroundColor: Colors.transparent,
      borderLeft: `1px solid ${Colors.blackOne}`,
      height: '100%',
      position: 'fixed',
      right: 0,
      top: 0,
      width: '50%',
      zIndex: 10,
    },
    cartInnerContent: {
      padding: '0 60px 200px',
      position: 'relative',
    },
    cartInnerContentHeader: {
      position: 'relative',
      width: '100%',
      '& > h2': {
        fontSize: '1.5rem',
        fontWeight: 500,
        marginBottom: '25px',
      },
    },
  },
  { name: 'CheckoutPanel' },
);

const CheckoutPanel = ({ leftPanel, rightPanel }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid className='checkout-grid' columns={2}>
        <GridColumn className='checkout-grid-left'>
          <h2 className='checkout-grid-tittle'>Please fill the following information</h2>
          {leftPanel}
        </GridColumn>
        <GridColumn className='checkout-grid-right'>
          <h2 className='checkout-grid-tittle'>Items in your cart</h2>
          {rightPanel}
        </GridColumn>
      </Grid>
    </div>
  );
};

export default React.memo(CheckoutPanel);
