import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { Grid, GridColumn } from 'semantic-ui-react';
import Colors from '@/constants/colors';
import { searchSelector } from '@/store/selector';
import RoomTypeItem from '@/components/common/RoomTypeItem';
import { SORT_ITEMS } from 'utils/constants';
import Breakpoints from '@/constants/breakpoints';
import '../common/Search.css';
import _ from 'lodash';

const useStyles = createUseStyles(
  {
    resultPanel: {
      position: 'fixed',
      width: '100% !important',
      height: '100%',
      backgroundColor: Colors.whiteOne,
      top: '0',
      left: '0',
      transform: 'translateY(100%)',
      transition: 'transform .75s cubic-bezier(.9,1,.32,1)',
      transitionDelay: '0s',
      zIndex: 99999,
      overflow: 'scroll',
      overscrollBehavior: 'none'
    },
    resultPanelActive: {
      composes: ['$resultPanel'],
      transform: 'translateY(0.5%) !important',
      transitionDelay: '.75s !important',
      zIndex: 99999,
    },
    [`@media (max-width: 1200px)`]: {
      resultPanel: {
        overflow: 'scroll',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      },
      resultPanelActive: {
        transform: 'translateY(0%) !important',
        top: '35px !important',
      },
    },
    [`@media (max-width: ${Breakpoints.xxl}px)`]: {
      resultPanelActive: {
        top: '90px',
      },
    },
    [`@media (min-width: 2300px)`]: {
      resultPanelActive: {
        transform: 'translateY(8%) !important',
      },
    },
  },
  { name: 'SearchResultPanel' },
);
const SearchResultPanel = ({
  showResults,
  backClick,
  property,
  handleItemSelected,
  results = [],
}) => {
  const classes = useStyles();
  const search = searchSelector();
  const [defaultResults, setDefaultResults] = useState(results);

  const onSortChange = (event) => {
    var value = event.target.value;
    var results;
    if (value === SORT_ITEMS.PRICE_HIGH_TO_LOW) {
      results = [...defaultResults].sort((a, b) => b.averageNightlyRate - a.averageNightlyRate);
    } else if (value === SORT_ITEMS.PRICE_LOW_TO_HIGH) {
      results = [...defaultResults].sort((a, b) => a.averageNightlyRate - b.averageNightlyRate);
    } else if (value === SORT_ITEMS.OCCUPANCY_HIGH_TO_LOW) {
      results = [...defaultResults].sort((a, b) => a.roomsAvailable - b.roomsAvailable);
    } else if (value === SORT_ITEMS.OCCUPANCY_LOW_TO_HIGH) {
      results = [...defaultResults].sort((a, b) => b.roomsAvailable - a.roomsAvailable);
    } else if (value === SORT_ITEMS.DEFAULT) {
      results = _.shuffle([...defaultResults]);
    }
    setDefaultResults(results);
  };

  useEffect(() => {
    setDefaultResults(results);
  }, [results]);

  return (
    <div className={showResults === true ? [classes.resultPanelActive] : classes.resultPanel}>
      <div>
        <div className="result-content-back-responsive" onClick={backClick}>
          <i className="arrow left icon"></i> Back
        </div>
        <div className="result-content">
          <Grid verticalAlign="middle" columns={2} stackable>
            <GridColumn>
              <div className="result-available-property">
                Rooms Available at {search.property.name}
              </div>
            </GridColumn>
            <GridColumn textAlign="right" className="sort-content">
              <select onChange={onSortChange} className="sort-select">
                <option key={SORT_ITEMS.DEFAULT} value={SORT_ITEMS.DEFAULT}>
                  {SORT_ITEMS.DEFAULT}
                </option>
                <option key={SORT_ITEMS.PRICE_HIGH_TO_LOW} value={SORT_ITEMS.PRICE_HIGH_TO_LOW}>
                  {SORT_ITEMS.PRICE_HIGH_TO_LOW}
                </option>
                <option key={SORT_ITEMS.PRICE_LOW_TO_HIGH} value={SORT_ITEMS.PRICE_LOW_TO_HIGH}>
                  {SORT_ITEMS.PRICE_LOW_TO_HIGH}
                </option>
                <option
                  key={SORT_ITEMS.OCCUPANCY_LOW_TO_HIGH}
                  value={SORT_ITEMS.OCCUPANCY_LOW_TO_HIGH}
                >
                  {SORT_ITEMS.OCCUPANCY_LOW_TO_HIGH}
                </option>
                <option
                  key={SORT_ITEMS.OCCUPANCY_HIGH_TO_LOW}
                  value={SORT_ITEMS.OCCUPANCY_HIGH_TO_LOW}
                >
                  {SORT_ITEMS.OCCUPANCY_HIGH_TO_LOW}
                </option>
              </select>
            </GridColumn>
          </Grid>
          <div className={classes.content}>
            <div
              className={
                defaultResults?.length > 0
                  ? classes.roomTypeItemContainerActive
                  : classes.roomTypeItemContainer
              }
            >
              {defaultResults?.length > 0 &&
                defaultResults.map((bookingSearchByDatesResponseItem, idx) => (
                  <RoomTypeItem
                    key={bookingSearchByDatesResponseItem.roomType.id}
                    bookingSearchByDatesResponseItem={{
                      ...bookingSearchByDatesResponseItem,
                      people: search.people,
                    }}
                    bestAvailableRate={idx == 0 ? false : false}
                    defaultRoomTypeImage={property.defaultRoomTypeImage}
                    handleItemSelected={handleItemSelected}
                  ></RoomTypeItem>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(SearchResultPanel);
