import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  item: null,
  showAbout: false,
  showSearch: false,
  showPolicies: false,
  friendlyUrl: null,
};

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    selectItem: (state, data) => {
      state.item = data.payload.item;
    },
    setShowAbout: (state, data) => {
      state.showAbout = data.payload.showAbout;
    },
    setShowSearch: (state, data) => {
      state.showSearch = data.payload.showSearch;
    },
    setShowPolicies: (state, data) => {
      state.showPolicies = data.payload.showPolicies;
    },
    setFriendlyUrl: (state, data) => {
      state.friendlyUrl = data.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { selectItem } = menuSlice.actions;
export const { setShowAbout } = menuSlice.actions;
export const { setShowSearch } = menuSlice.actions;
export const { setShowPolicies } = menuSlice.actions;
export const { setFriendlyUrl } = menuSlice.actions;

export default menuSlice.reducer;
