import React from 'react';
import { createUseStyles } from 'react-jss';
import Colors from '@/constants/colors';
import { searchSelector } from '@/store/selector';

const useStyles = createUseStyles(
  {
    bookButton: (properties) => ({
      cursor: 'pointer',
      transition: 'all .4s ease-in-out',
      bottom: '30px',
      borderColor: Colors.blackOne,
      fontFamily: 'Canela-Regular,serif',
      fontSize: '1.6rem',
      height: '60px',
      left: '30px',
      letterSpacing: 0,
      lineHeight: '60px',
      opacity: 1,
      position: 'absolute',
      textTransform: 'none',
      width: 'calc(100% - 60px)',
      textAlign: 'center',
      color: Colors.blackTwo,
      backgroundColor: Colors.transparentTwo,
      border: `1px solid ${Colors.grayOne}`,
      zIndex: 2,
      '&:hover': {
        background: properties.propertyColor,
        texShadow: 'none',
        color: properties.fontColor
      },
    }),
  },
  { name: 'BookingButton' },
);

const BookingButton = ({ text, onClick }) => {
  const search = searchSelector();
  const classes = useStyles({ propertyColor: search.property.color, fontColor: search.property.fontColor });
  return (
    <div className={classes.bookButton} onClick={onClick}>
      {text}
      {/* ${bookingSearchByDatesResponseItem.averageNightlyRate} / night - Book */}
    </div>
  );
};

export default React.memo(BookingButton);
