import React from 'react';
import { createUseStyles } from 'react-jss';
import Colors from '@/constants/colors';
import { searchSelector } from '@/store/selector';
import IconButtons from '@/components/Buttons/IconButtons';
import '../common/Search.css';

const useStyles = createUseStyles(
  {
    noResultContainer: {
      backgroundColor: Colors.blueTwo,
      height: '60px',
      width: '100%',
      position: 'fixed',
      textAlign: 'center',
      zIndex: 0,
      '& p': {
        color: Colors.whiteOne,
        // font-family: "Work-Sans", sans-serif,
        fontSize: '1rem',
        fontWeight: 'bold',
        margin: 0,
        lineHeight: '60px',
        display: 'inline-block',
        // padding: '30px 50px',
      },
      transition: 'transform .75s cubic-bezier(.23,1,.32,1)',
      transform: 'translateY(0px)',
      bottom: '-60px'
    },
    noResultContainerActive: {
      composes: ['$noResultContainer'],
      transform: 'translateY(-135px) !important',
    },
    closeNoresultContainer: {
      float: 'right',
      position: 'relative',
      top: '15px',
      backgroundColor: 'transparent !important',
      '& i': {
        color: `${Colors.whiteOne} !important`,
      },
    },
    [`@media (max-width: 1200px)`]: {
      noResultContainer: {
        zIndex: '99 !important',
        padding: '12px',
        height: '110px !important',
        '& p': {
          lineHeight: '22px !important'
        }
      },
      closeNoresultContainer: {
        top: '-108px !important',
        right: '-26px !important',
        fontSize: '15px !important'
      },
    }
  },
  { name: 'SearchNoResultPanel' },
);
const SearchNoResultPanel = ({showNoResult, setShowNoResult}) => {
  const classes = useStyles();
  const search = searchSelector();
  return (
    <div
          className={!showNoResult ? classes.noResultContainer : classes.noResultContainerActive}
        >
          <p>
          There is a night or night(s) in the date range searched that require a longer length of stay for these dates. Please contact us directly to review your available options:{' '}
            {search.property.contact.phone}
          </p>
          <IconButtons.Close
            className={classes.closeNoresultContainer}
            onClick={() => setShowNoResult(false)}
          />
        </div>
  );
};
export default React.memo(SearchNoResultPanel);
