import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: null,
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSession: (state, data) => {
      state.token = data.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSession } = sessionSlice.actions;

export default sessionSlice.reducer;
