import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createUseStyles } from 'react-jss';
import CartTotal from './CartTotal';
import Colors from '@/constants/colors';
import { cartSelector } from '@/store/selector';
import CartHelper from '@/helpers/cartHelper';
import { createStripePaymentIntent } from '@/api/stripeAPI';
import { createReservation } from '@/api/reservationAPI';
import { Dimmer, Loader } from 'semantic-ui-react';
import { setCartError, setReservationConfirmation } from '@/components/cartSlice';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import CartItemList from './CartItemList';
import { searchSelector } from '@/store/selector';
import { cartAPI } from '@/api/reservationAPI';
import { addItems, setPromoCode } from '@/components/cartSlice';

const useStyles = createUseStyles(
  {
    root: {},
    CartSummary: {
      backgroundColor: Colors.whiteOne,
      bottom: '-1px',
      position: 'fixed',
      right: 0,
      width: '50%',
    },
    bookButton: {
      //  font-family: "Canela-Regular", serif;
      fontSize: '1.8rem',
      height: '60px',
      letterSpacing: 0,
      lineHeight: '58px',
      margin: '30px auto',
      textTransform: 'none',
      width: 'calc(100% - 120px)',
      color: Colors.blackTwo,
      backgroundColor: 'transparent',
      border: `1px solid ${Colors.grayOne}`,
      transition: 'all .4s ease-in-out',
      display: 'block',
      textAlign: 'center',
      cursor: 'pointer',
      overflow: 'hidden',
      position: 'relative',
      zIndex: 2,
      '&:hover': {
        background: Colors.blueOne,
        texShadow: 'none',
        color: Colors.whiteOne,
      },
      visibility: 'hidden',
      opacity: 0,
    },
    bookButtonActive: {
      composes: ['$bookButton'],
      visibility: 'visible',
      opacity: 1,
    },
    bookButtonContainer: {
      position: 'relative',
      top: '-1px',
      left: '-1px',
      border: `1px solid ${Colors.blackOne}`,
      borderRight: 'none',
      width: 'calc(100% + 1px)',
    },
    errorContainer: {
      backgroundColor: Colors.redTwo,
      bottom: '182px',
      position: 'fixed',
      right: 0,
      // transition: 'transform 400ms cubic-bezier(0.165, 0.84, 0.44, 1)',
      width: '50%',
      '& p': {
        color: Colors.whiteOne,
        // font-family: "Work-Sans", sans-serif,
        fontSize: '1rem',
        fontWeight: 'bold',
        margin: 0,
        padding: '30px 50px',
      },
      transition: 'transform .75s cubic-bezier(.23,1,.32,1)',
      transform: 'translateY(0%)',
    },
    errorContainerActive: {
      composes: ['$errorContainer'],
      transform: 'translateY(100%) !important',
    },
    [`@media (max-width: 1200px)`]: {
      CartSummary: {
        width: '100% !important',
      },
      errorContainer: {
        width: '100% !important',
        bottom: '122px !important',
      },
      bookButton: {
        margin: '6px auto !important',
      },
    },
    updateItemsLoader: {
      position: 'fixed !important',
      top: '45% !important',
      left: '75% !important',
    },
  },
  { name: 'RightCheckoutPanel' },
);

const RightCheckoutPanel = () => {
  const classes = useStyles();
  const cart = cartSelector();
  const search = searchSelector();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const cartError = cart.error;
  const [loading, setLoading] = useState(false);
  const [loadingItems, setLoadingItems] = useState(false);
  const [createReservationError, setCreateReservationError] = useState(false);
  const { due } = CartHelper.cartSummary(cart);
  const showCheckout =
    CartHelper.isCustomerValid(cart.customer) &&
    (CartHelper.isPaymentValid(cart.payment) || due <= 0) &&
    !createReservationError;
  const message = cartError.message;

  const onError = (error) => {
    setLoading(false);
    dispatch(setCartError({ message: error.response.data }));
  };

  const onCreateReservationError = (error) => {
    onError(error);
    setCreateReservationError(true);
  };

  const onReservationCreated = (reservation) => {
    dispatch(setReservationConfirmation(reservation));
    navigate('/checkout/success', { state: cart });
  };

  const onStripePaymentCreated = async (cl) => {
    const payload = await stripe.confirmCardPayment(cl, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });
    if (payload.error) {
      dispatch(setCartError({ message: `Payment failed: ${payload.error.message}` }));
      setLoading(false);
    } else {
      const { total, due, giftCardAmountApplied } = CartHelper.cartSummary(cart);
      createReservation(
        cart.customer,
        total,
        due,
        CartHelper.findCartProperty(cart).id,
        payload.paymentIntent.amount,
        payload.paymentIntent.id,
        cart.items,
        cart.promoCode,
        cart.giftCard != null ? cart.giftCard.id : null,
        giftCardAmountApplied,
        false
      )
        .then((res) => {
          onReservationCreated(res.data);
        })
        .catch(onCreateReservationError);
    }
  };

  const checkoutClick = () => {
    if (showCheckout) {
      setLoading(true);
      const { total, due } = CartHelper.cartSummary(cart);
      if (due > 0){
        createStripePaymentIntent(
          cart.customer,
          total,
          due,
          CartHelper.findCartProperty(cart).id,
          cart.items,
        )
          .then((res) => {
            onStripePaymentCreated(res.data);
          })
          .catch(onError);
      } else{
        const { total, due, giftCardAmountApplied } = CartHelper.cartSummary(cart);
        createReservation(
          cart.customer,
          total,
          due,
          CartHelper.findCartProperty(cart).id,
          0,
          null,
          cart.items,
          cart.promoCode,
          cart.giftCard != null ? cart.giftCard.id : null,
          giftCardAmountApplied,
          true
        )
          .then((res) => {
            onReservationCreated(res.data);
          })
          .catch(onCreateReservationError);
        }
  }
  };

  useEffect(() => {
    setCreateReservationError(false);
  }, []);

  const onRemoveItem = (item) => {
    setLoadingItems(true);
    dispatch(setPromoCode(null));
    cartAPI(
      {
        propertyId: CartHelper.findCartProperty(cart).id,
        items: cart.items.filter((itemInCart) => item.roomType.id !== itemInCart.roomType.id),
      },
      search.people,
    )
      .then((res) => {
        setLoadingItems(false);
        if (res.data?.itemList) {
          dispatch(addItems(res.data.itemList));
        }
      })
      .catch((error) => {
        setLoadingItems(false);
        console.log(error);
      });
  };

  return (
    <Dimmer.Dimmable dimmed={loadingItems}>
      <Dimmer active={loadingItems} inverted>
        <Loader className={classes.updateItemsLoader}>Updating cart items</Loader>
      </Dimmer>
      <CartItemList readOnly={false} onRemoveItem={onRemoveItem}></CartItemList>
      <div className={message && !loading ? classes.errorContainer : classes.errorContainerActive}>
        <p>{message}</p>
      </div>
      <div className={classes.CartSummary}>
        <CartTotal></CartTotal>
        <div className={classes.bookButtonContainer}>
          <Dimmer.Dimmable dimmed={loading}>
            <Dimmer active={loading}>
              <Loader>Processing payment, please wait</Loader>
            </Dimmer>
            <div
              onClick={checkoutClick}
              className={showCheckout === true ? classes.bookButtonActive : classes.bookButton}
            >
              Checkout
            </div>
          </Dimmer.Dimmable>
        </div>
      </div>
    </Dimmer.Dimmable>
  );
};

export default React.memo(RightCheckoutPanel);
