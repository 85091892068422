import React from 'react';
import { createUseStyles } from 'react-jss';
import { Container, Form } from 'semantic-ui-react';
import Colors from '@/constants/colors';
import IconButtons from '@/components/Buttons/IconButtons';
import { searchSelector } from '@/store/selector';
import WaveCalendar from '../Calendar/WaveCalendar';
import '../common/Search.css';

const useStyles = createUseStyles(
  {
    searchResponsivePanel: {
      position: 'fixed',
      width: '100% !important',
      height: '100%',
      backgroundColor: Colors.whiteOne,
      top: '0',
      left: '0',
      transform: 'translateY(138%)',
      transition: 'transform .75s cubic-bezier(.23,1,.32,1)',
      zIndex: 99,
      borderTop: '1px solid #ccc',
    },
    searchResponsivePanelActive: {
      composes: ['$searchResponsivePanel'],
      transform: 'translateY(0) !important',
    },
    searchDatesPanel: {
      position: 'fixed',
      width: '100% !important',
      height: '100%',
      backgroundColor: Colors.whiteOne,
      top: '65px',
      right: '0',
      transform: 'translateX(138%)',
      transition: 'transform .75s cubic-bezier(.23,1,.32,1)',
      zIndex: 99,
      borderTop: '1px solid #ccc',
    },
    searchDatesPanelActive: {
      composes: ['$searchResponsivePanel'],
      transform: 'translateX(0) !important',
    },
    closeSearchDatesButton: {
      position: 'absolute',
      top: '65px',
      right: '0px',
      left: '0px',
      marginRight: '0px !important',
      marginLeft: '0px !important',
      backgroundColor: 'transparent !important',
      '& i': {
        fontSize: '25px',
        color: `${Colors.blackTwo} !important`,
      },
    },
  },
  { name: 'ResponsiveDatesPanel' },
);
const ResponsiveDatesPanel = ({ showDatesPanel, loadingCalendar, setShowDates, datesChanged }) => {
  const classes = useStyles();
  const search = searchSelector();
  return (
    <div
      className={
        showDatesPanel === true ? classes.searchDatesPanelActive : classes.searchDatesPanel
      }
    >
      <Container>
        <IconButtons.Close
          className={classes.closeSearchDatesButton}
          onClick={() => setShowDates(false)}
        />
        <div className="search-content">
          <WaveCalendar
            availability={search.availability}
            onDateChanged={datesChanged}
            roomType={search.roomType}
            rates={search.rates}
          />
          <div
            className={
              loadingCalendar === true
                ? 'calendar-dimmer calendar-dimmer-active'
                : 'calendar-dimmer'
            }
          >
            <Form className="calendar-dimmer-form" loading={false}></Form>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default React.memo(ResponsiveDatesPanel);
