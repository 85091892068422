import React, { useState, useEffect } from 'react';
import { Form, Input, Dropdown } from 'semantic-ui-react';
import { listCountry, listState } from '@/api/countryAPI';
import { cartSelector } from '@/store/selector';
import { useDispatch } from 'react-redux';
import { setCustomer } from '@/components/cartSlice';
import Joi from 'joi';
import ValidationHelper from '@/helpers/validationHelper';
import PromoCodePanel from './PromoCodePanel';

const profileSchema = Joi.object({
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  phoneNumber: Joi.string().required(),
  city: Joi.string().required(),
  address: Joi.string().required(),
  email: Joi.string().required(),
});

const GuestForm = ({ classes }) => {
  const dispatch = useDispatch();
  const cart = cartSelector();
  const customer = cart.customer;
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [guest, setGuest] = useState({ ...customer });
  const [errors, setErrors] = useState({
    firstName: null,
    lastName: null,
    phoneNumber: null,
    city: null,
    address: null,
    email: null,
  });

  useEffect(() => {
    setCountryList(
      listCountry().map((country) => ({
        key: country.alpha2Code,
        text: country.name,
        value: country.name,
      })),
    );
    setStateList(
      listState().map((state) => ({
        key: state.abbreviation,
        text: state.name,
        value: state.name,
      })),
    );
  }, []);

  const { firstName, lastName, phoneNumber, country, city, address, state, email } = guest;

  const onChange = (_event, data) => {
    const { id, value } = data;
    setErrors({ ...errors, [id]: null });
    if (id === 'country') {
      const { key } = data.options.find((o) => o.value === value);
      guest.countryAlphaCode = key;
      if (value == 'United States of America') {
        guest.state = 'Alabama';
      } else {
        guest.state = undefined;
      }
    }
    setGuest({ ...guest, [id]: value });
  };

  const onBlur = () => {
    ValidationHelper.validateSchema(profileSchema, guest).catch(setErrors);
    dispatch(setCustomer({ ...guest }));
  };

  return (
    <>
      <Form className={classes.root}>
        <div className={classes.header}>
          <h2>Guest Information</h2>
        </div>
        <Form.Group widths="equal">
          <Form.Field
            id="firstName"
            control={Input}
            label="First Name"
            type="text"
            onChange={onChange}
            onBlur={onBlur}
            value={firstName}
            error={!!errors?.firstName}
          />
          <Form.Field
            id="lastName"
            control={Input}
            label="Last Name"
            type="text"
            onChange={onChange}
            onBlur={onBlur}
            value={lastName}
            error={!!errors?.lastName}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            id="phoneNumber"
            control={Input}
            label="Phone Number"
            type="text"
            onChange={onChange}
            onBlur={onBlur}
            value={phoneNumber}
            error={!!errors?.phoneNumber}
          />
          <Form.Field
            id="email"
            control={Input}
            label="Email"
            type="text"
            onChange={onChange}
            onBlur={onBlur}
            value={email}
            error={!!errors?.email}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            id="city"
            control={Input}
            label="City"
            type="text"
            onChange={onChange}
            onBlur={onBlur}
            value={city}
            error={!!errors?.city}
          />
          <Form.Field
            id="address"
            control={Input}
            label="Address"
            type="text"
            onChange={onChange}
            onBlur={onBlur}
            value={address}
            error={!!errors?.address}
          />
        </Form.Group>
        <Form.Group widths="2">
          <div className="field">
            <label htmlFor="country">Country</label>
            <Dropdown
              id="country"
              options={countryList}
              placeholder="Select Country"
              label="Country"
              onChange={onChange}
              onBlur={onBlur}
              value={country}
              search
              fluid
              selection
            />
          </div>
          {!!country && country == 'United States of America' && (
            <div className="field">
              <label htmlFor="state">State</label>
              <Dropdown
                id="state"
                options={stateList}
                placeholder="Select State"
                onChange={onChange}
                onBlur={onBlur}
                value={state}
                search
                fluid
                selection
              />
            </div>
          )}
        </Form.Group>
      </Form>
      <PromoCodePanel />
    </>
  );
};

export default React.memo(GuestForm);
