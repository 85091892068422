import api from './api';

const URLS = {
  getPropertyData: 'getPropertyData',
  getPropertyIdByFriendlyUrl: 'getPropertyIdByFriendlyUrl',
  listPropertyByCompany: 'listPropertyByCompany',
};

export const getPropertyData = (body) => {
  return api.post(URLS.getPropertyData, body);
};

export const listPropertyByCompany = (body) => {
  return api.post(URLS.listPropertyByCompany, body);
};

export const getPropertyIdByFriendlyUrl = (body) => {
  return api.post(URLS.getPropertyIdByFriendlyUrl, body);
};
