import React from 'react';
import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import RoomTypeHomeItem from './RoomTypeHomeItem';
import { Grid, GridColumn } from 'semantic-ui-react';

const useStyles = createUseStyles(
  {
    root: {
      display: 'block',
    },
  },
  { name: 'RoomTypesSection' },
);
const RoomTypesSection = ({ property, handleItemSelected }) => {
  const classes = useStyles();
  const [maxItems, setMaxItems] = useState(6);
  return (
    property && (
      <div className={classes.root}>
        <Grid columns="3" className="stackable">
          {property != null &&
            property.roomTypeList != null &&
            property.roomTypeList.length > 0 &&
            property.roomTypeList.slice(0, maxItems).map((roomType, idx) => (
              <GridColumn key={idx} className="room-type-column">
                <RoomTypeHomeItem
                  key={roomType.id}
                  roomType={roomType}
                  property={property}
                  handleItemSelected={handleItemSelected}
                ></RoomTypeHomeItem>
              </GridColumn>
            ))}
        </Grid>
        {maxItems != property.roomTypeList.length && (
          <div className="show-more-rooms-button-content">
            <a
              className="show-more-rooms-button"
              onClick={() => setMaxItems(property.roomTypeList.length)}
            >
              View More Rooms At {property.name}{' '}
            </a>
          </div>
        )}
      </div>
    )
  );
};
export default React.memo(RoomTypesSection);
