import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { Form, Input, Dropdown, Button, Message } from 'semantic-ui-react';
import ValidationHelper from '@/helpers/validationHelper';
import Joi from 'joi';
import { listCountry, listState } from '@/api/countryAPI';
import { createGiftCardReservation } from '@/api/reservationAPI';

import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import Colors from '@/constants/colors';
import { getStripeCredentials, createStripePaymentIntentForGiftCard } from '@/api/stripeAPI';
import { loadStripe } from '@stripe/stripe-js';

const profileSchema = Joi.object({
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  phoneNumber: Joi.string().required(),
  city: Joi.string().required(),
  address: Joi.string().required(),
  email: Joi.string().required(),
  amount: Joi.number().required(),
});

const cardStyle = {
  style: {
    base: {
      color: Colors.blackTwo,
      fontFamily: 'Arial,Helvetica,sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '1.2rem',
      '::placeholder': {
        color: Colors.grayThree,
      },
    },
    invalid: {
      // fontFamily: 'Arial, sans-serif',
      color: Colors.redOne,
      iconColor: Colors.redOne,
    },
  },
};

const useStyles = createUseStyles(
  {
    root: {
      height: 'calc(100% - 145px)',
      overflowY: 'scroll',
      overflowX: 'hidden',
    },
    about: {
      paddingLeft: '25px',
    },
    aboutPanel: {
      position: 'fixed',
      width: '50% !important',
      height: '100% !important',
      backgroundColor: '#fff',
      right: '0',
      top: '70px',
      transform: 'translateX(138%)',
      transition: 'transform .75s cubic-bezier(.23,1,.32,1)',
      zIndex: 9,
      padding: '20px',
    },
    aboutPanelActive: {
      composes: '$aboutPanel',
      transform: 'translateX(0) !important',
    },
    address: {
      marginTop: '8px',
      paddingLeft: '5px',
      fontSize: '12px',
    },
    addressContent: {
      display: 'flex',
      paddingLeft: '25px',
    },
    header: {
      position: 'relative',
      marginBottom: '15px',
      '& > h2': {
        display: 'inline-block',
        fontSize: '1.2rem',
        lineHeight: '25px',
        // marginLeft: '18px',
        width: 'calc(100% - 50px)',
      },
    },
    [`@media (max-width: 1200px)`]: {
      aboutPanel: {
        zIndex: '9999999 !important',
        width: '100% !important',
        height: '100% !important',
        top: '0 !important',
      },
    },
  },
  { name: 'GiftCard' },
);

const GiftCardElementsContainer = (props) => {
  const [stripePromise, setStripePromise] = useState(null);
  const [stripeOptions, setStripeOptions] = useState(null);

  return (
    <Elements stripe={stripePromise} options={stripeOptions}>
      <GiftCard
        {...props}
        setStripePromise={setStripePromise}
        setStripeOptions={setStripeOptions}
        stripePromise={stripePromise}
        stripeOptions={stripeOptions}
      />
    </Elements>
  );
};

const initialFormData = {
  country: 'United States of America',
  state: 'Alabama',
  countryAlphaCode: 'US',
};

const initialErrorData = {
  firstName: null,
  lastName: null,
  phoneNumber: null,
  city: null,
  address: null,
  email: null,
  amount: null,
  paymentFulfilled: false,
};

const GiftCard = ({
  property,
  setStripePromise,
  setStripeOptions,
  stripePromise,
  stripeOptions,
  onCloseGiftCardPanel,
  ...props
}) => {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const [form, setForm] = useState({ ...initialFormData, propertyId: property.id });

  const [errors, setErrors] = useState(initialErrorData);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [savingGiftCard, setSavingGiftCard] = useState(false);
  const [giftCardCreated, setGiftCardCreated] = useState(false);
  const [giftCardError, setGiftCardError] = useState(null);
  const [showGiftCardButton, setShowGiftCardButton] = useState(true);

  useEffect(() => {
    setCountryList(
      listCountry().map((country) => ({
        key: country.alpha2Code,
        text: country.name,
        value: country.name,
      })),
    );
    setStateList(
      listState().map((state) => ({
        key: state.abbreviation,
        text: state.name,
        value: state.name,
      })),
    );
  }, []);

  const { firstName, lastName, phoneNumber, country, city, address, state, email, amount } = form;

  const onChange = (_event, data) => {
    const { id, value } = data;
    setErrors({ ...errors, [id]: null });
    if (id === 'country') {
      const { key } = data.options.find((o) => o.value === value);
      form.countryAlphaCode = key;
      if (value == 'United States of America') {
        form.state = 'Alabama';
      } else {
        form.state = undefined;
      }
    }
    setForm({ ...form, [id]: value });
  };

  const handleChange = async (event) => {
    // console.log(event);
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    // setDisabled(event.empty);
    // setError(event.error ? event.error.message : "");
    if (!event.error && event.complete) {
      //dispatch(setPayment({ paymentFulfilled: true }));
      setForm({ ...form, postalCode: event.value.postalCode, paymentFulfilled: true });
    }
    if ((event.error || !event.complete) && form.paymentFulfilled) {
      setForm({ ...form, postalCode: event.value.postalCode, paymentFulfilled: false });
    }
  };

  const onBlur = (_event) => {
    const { id } = _event.target;
    console.log(form);
    ValidationHelper.validateSchema(profileSchema, form)
      .then(() => {
        if (!form.paymentFulfilled || id == 'amount') {
          getStripeCredentials(property.id)
            .then((res) => {
              let stripeCredentials = res.data;
              if (stripePromise == null) setStripePromise(loadStripe(stripeCredentials.publicKey));
              createStripePaymentIntentForGiftCard({
                propertyId: property.id,
                amount: form.amount,
              }).then((response) =>
                setStripeOptions({
                  // passing the client secret obtained from the server
                  clientSecret: response.data,
                }),
              );
            })
            .catch((error) => console.log(error));
        }
      })
      .catch((error) => {
        setErrors(error);
        setSavingGiftCard(false);
        setGiftCardCreated(false);
      });
  };

  const onClickSubmit = () => {
    setSavingGiftCard(true);
    ValidationHelper.validateSchema(profileSchema, form)
      .then(async () => {
        const stripePayload = await stripe.confirmCardPayment(stripeOptions.clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement),
          },
        });
        if (stripePayload.error) {
          /*dispatch(setCartError({ message: `Payment failed: ${payload.error.message}` }));
          setLoading(false);*/
          setGiftCardError(stripePayload.error.message);
          setSavingGiftCard(false);
        } else {
          let giftCardCodeData = {
            propertyId: form.propertyId,
            firstName: form.firstName,
            lastName: form.lastName,
            email: form.email,
            phone: form.phoneNumber,
            address: form.address,
            city: form.city,
            state: form.state,
            country: form.country,
            countryAlphaCode: form.countryAlphaCode,
            zip: form.postalCode,
            amount: form.amount,
            paymentIntentId: stripePayload.paymentIntent.id,
          };
          createGiftCardReservation(giftCardCodeData)
            .then(() => {
              setGiftCardError(null);
              setGiftCardCreated(true);
              setShowGiftCardButton(false);
              let card = elements.getElement(CardElement);
              card.clear();
            })
            .catch((error) => {
              setGiftCardError(error);
              setShowGiftCardButton(false);
              setGiftCardCreated(false);
              let card = elements.getElement(CardElement);
              card.clear();
            });
        }
      })
      .catch((error) => {
        setErrors(error);
        setSavingGiftCard(false);
        setGiftCardCreated(false);
      });
  };

  const onMouseLeave = () => {
    onCloseGiftCardPanel(false);
  };

  return (
    <div
      className={props.show === true ? [classes.aboutPanelActive] : classes.aboutPanel}
      onMouseLeave={onMouseLeave}
    >
      <div className={classes.root}>
        <Form>
          <div className={classes.header}>
            <h2>Gift Card Information</h2>
          </div>
          <Form.Group widths="equal">
            <Form.Field
              id="firstName"
              control={Input}
              label="First Name"
              type="text"
              onChange={onChange}
              onBlur={onBlur}
              value={firstName}
              error={!!errors?.firstName}
            />
            <Form.Field
              id="lastName"
              control={Input}
              label="Last Name"
              type="text"
              onChange={onChange}
              onBlur={onBlur}
              value={lastName}
              error={!!errors?.lastName}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              id="email"
              control={Input}
              label="Email"
              type="text"
              onChange={onChange}
              onBlur={onBlur}
              value={email}
              error={!!errors?.email}
            />
            <Form.Field
              id="phoneNumber"
              control={Input}
              label="Phone Number"
              type="text"
              onChange={onChange}
              onBlur={onBlur}
              value={phoneNumber}
              error={!!errors?.phoneNumber}
            />
          </Form.Group>
          <Form.Group widths="2">
            <div className="field">
              <label htmlFor="country">Country</label>
              <Dropdown
                id="country"
                options={countryList}
                placeholder="Select Country"
                label="Country"
                onChange={onChange}
                onBlur={onBlur}
                value={country}
                search
                fluid
                selection
              />
            </div>
            {!!country && country == 'United States of America' && (
              <div className="field">
                <label htmlFor="state">State</label>
                <Dropdown
                  id="state"
                  options={stateList}
                  placeholder="Select State"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={state}
                  search
                  fluid
                  selection
                />
              </div>
            )}
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              id="city"
              control={Input}
              label="City"
              type="text"
              onChange={onChange}
              onBlur={onBlur}
              value={city}
              error={!!errors?.city}
            />
            <Form.Field
              id="address"
              control={Input}
              label="Address"
              type="text"
              onChange={onChange}
              onBlur={onBlur}
              value={address}
              error={!!errors?.address}
            />
          </Form.Group>
          <Form.Group widths="2">
            <Form.Field
              id="amount"
              control={Input}
              label="Amount"
              type="number"
              onChange={onChange}
              onBlur={onBlur}
              value={amount}
              error={!!errors?.amount}
            />
          </Form.Group>
          {stripePromise && stripeOptions && (
            <Form.Group widths="equal">
              <Form.Field>
                <label htmlFor="card-element">Card Information</label>
                <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
              </Form.Field>
            </Form.Group>
          )}
          {showGiftCardButton && stripePromise && stripeOptions && (
            <Form.Group widths="2">
              <Form.Field
                control={Button}
                label="&nbsp;"
                onClick={onClickSubmit}
                type="button"
                color="green"
                content="Buy Gift Card"
                loading={savingGiftCard}
              />
            </Form.Group>
          )}
          {!!giftCardError && <Message negative>{giftCardError}</Message>}
          {!!giftCardCreated && (
            <Message positive>
              Gift Cart created ! You will be receiving the gift card info in your mail inbox.
            </Message>
          )}
        </Form>
      </div>
    </div>
  );
};

export default React.memo(GiftCardElementsContainer);
