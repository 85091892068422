import React from 'react';
import BookingEngineLayout from '@/layout/BookingEngineLayout';
import { createUseStyles } from 'react-jss';
import wave from '@/assets/images/wave.svg';
import { useNavigate } from 'react-router-dom';

const useStyles = createUseStyles(
  {
    errorWrapper: {
      display: 'block',
      // font-family: "Work-Sans", sans-serif,
      fontSize: '1.5rem',
      lineHeight: 'normal',
      margin: '30px 0 0',
      position: 'relative',
      textAlign: 'center',
    },
    headline: { fontWeight: 'bold' },
    message: {},
    image: {
      marginBottom: '1em',
    },
    link: {
      cursor: 'pointer',
    },
  },
  { name: 'ErrorPageView' },
);

const ErrorPageView = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <BookingEngineLayout showCustomTop={false}>
      <div className={classes.errorWrapper}>
        <img src={wave} alt="wave" className={classes.image} />
        <p className={classes.headline}>There was a problem with your request</p>
        <p className={classes.message}>Please try again later.</p>
        <a onClick={() => navigate(-1)} className={classes.link}>
          Back
        </a>
      </div>
    </BookingEngineLayout>
  );
};

export default React.memo(ErrorPageView);
