import React from 'react';
import { createUseStyles } from 'react-jss';
import { Container, Grid, GridColumn } from 'semantic-ui-react';
import Flickity from 'react-flickity-component';
import 'flickity-bg-lazyload';
import './PropertySection.css';
import '@/assets/flickity.css';
import Colors from '@/constants/colors';

const useStyles = createUseStyles(
  {
    sliderImageResponsive: {
      opacity: 0.2,
      left: '0%',
      width: '100%',
      height: '100%',
      zIndex: 0,
      position: 'absolute',
    },
    sliderImage: {
      left: '0%',
      width: '100%',
      height: '500px',
      zIndex: 0,
      position: 'absolute',
      display: 'grid',
      overflow: 'hidden',
      background: `${Colors.grayOne}`,
    },
    sliderImageInside: {
      width: '200%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    },
    [`@media (min-width: 2200px)`]: {
      sliderImage: {
        left: '0%',
        width: '100%',
        height: '720px',
        zIndex: 0,
        position: 'absolute',
        display: 'grid',
        overflow: 'hidden',
        backgroundPosition: '50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        background: `${Colors.grayOne}`,
      },
    },
  },
  { name: 'PropertySection' },
);
const PropertySection = ({ property }) => {
  const classes = useStyles();
  const flickityOptions = {
    initialIndex: 1,
    wrapAround: true,
    bgLazyLoad: 100,
    className: 'slider',
  };

  const flickityOptions1 = {
    initialIndex: 0,
    wrapAround: true,
    lazyLoad: 1,
    setGallerySize: false,
    pageDots: false,
  };
  return (
    property && (
      <div>
        <div>
          <div className="section-title">Our Property</div>
          <div className="custom-slider">
            <Flickity
              options={flickityOptions}
              disableImagesLoaded={false} // default false
              reloadOnUpdate // default false
              static
              wrapAround={true}
            >
              {property != null &&
                property.imageList != null &&
                property.imageList.length > 0 &&
                property.imageList.map((image, idx) => (
                  <div className="slide" key={idx}>
                    <div className="slide-content">
                      <div className={classes.sliderImage}>
                      <div
                        className={classes.sliderImageInside}
                        data-flickity-bg-lazyload={encodeURI(image.url)}
                      ></div>
                      </div>
                    </div>
                  </div>
                ))}
            </Flickity>
          </div>
          <div className="responsive-slider">
            <Flickity
              className={'slider'} // default ''
              elementType={'div'} // default 'div'
              options={flickityOptions1}
              disableImagesLoaded={false} // default false
              reloadOnUpdate // default false
              static
              wrapAround={true}
            >
              {property != null &&
                property.imageList != null &&
                property.imageList.length > 0 &&
                property.imageList.map((image, idx) => (
                  <img
                    key={idx}
                    className={classes.sliderImageResponsive}
                    alt=""
                    data-flickity-lazyload={image.url}
                  />
                ))}
            </Flickity>
          </div>
        </div>
        <Container className="property-content custom-property-section">
          <Grid columns={2}>
            <GridColumn>
              <div dangerouslySetInnerHTML={{ __html: property.description }}></div>
            </GridColumn>
            <GridColumn>
              <div className="property-attributes">
                <ul className="attributes-container">
                  {property != null &&
                    property.attributeList != null &&
                    property.attributeList.length > 0 &&
                    property.attributeList.map((attribute, idx) => (
                      <li key={idx}>{attribute.title}</li>
                    ))}
                </ul>
              </div>
            </GridColumn>
          </Grid>
        </Container>
        <Container className="property-content responsive-property-section">
          <div>
            <div dangerouslySetInnerHTML={{ __html: property.description }}></div>
          </div>
          <div>
            <div className="property-attributes">
              <ul className="attributes-container">
                {property != null &&
                  property.attributeList != null &&
                  property.attributeList.length > 0 &&
                  property.attributeList.map((attribute, idx) => (
                    <li key={idx}>{attribute.title}</li>
                  ))}
              </ul>
            </div>
          </div>
        </Container>
      </div>
    )
  );
};
export default React.memo(PropertySection);
