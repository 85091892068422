import React, { useState } from 'react';
import { Form, Input, Button, Message } from 'semantic-ui-react';
import { findValidPromoCodeDataByCode } from '@/api/promoCodeAPI';
import { cartSelector, companySelector } from '@/store/selector';
import { useDispatch } from 'react-redux';
import { addItems, setPromoCode as setPromoCodeToCart, setGiftCard } from '@/components/cartSlice';
import Joi from 'joi';
import ValidationHelper from '@/helpers/validationHelper';
import { createUseStyles } from 'react-jss';

const profileSchema = Joi.object({
  promoCode: Joi.string().required().messages({
    'string.base': 'Please enter a promotion code',
    'any.required': 'Please enter a promotion code',
    'string.empty': 'Please enter a promotion code',
  }),
});

const useStyles = createUseStyles(
  {
    root: {},
    applyDiscountButton: {
      height: 'max-content',
    },
  },
  { name: 'PromoCodePanel' },
);

const PromoCodePanel = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cart = cartSelector();
  const company = companySelector();
  const [promoCode, setPromoCode] = useState(null);
  const [restrictedDates, setRestrictedDates] = useState([]);

  const [errors, setErrors] = useState({
    promoCode: null,
  });
  const [loadingPromotion, setLoadingPromotion] = useState(false);

  const onChange = (_event, data) => {
    const { id, value } = data;
    setErrors({ ...errors, [id]: null });
    setPromoCode(value);
  };

  const onClickSubmit = () => {
    ValidationHelper.validateSchema(profileSchema, { promoCode: promoCode })
      .then(() => {
        let promoCodeData = {
          code: promoCode,
          itemList: cart.items,
          propertyId: company.property.id,
        };
        setLoadingPromotion(true);
        findValidPromoCodeDataByCode(promoCodeData)
          .then((response) => {
            setLoadingPromotion(false);
            if (response.data.response == 'OK') {
              setErrors({ ...errors, ['promoCode']: null });
              if (response.data.promoCodeResponse != null) {
                dispatch(addItems(response.data.promoCodeResponse.itemList));
                dispatch(setPromoCodeToCart(promoCode));
                setRestrictedDates(response.data.promoCodeResponse.restrictionDateList);
              } else {
                let gifCard = {
                  id: response.data.giftCardResponse.giftCardId,
                  amount: response.data.giftCardResponse.amount,
                  code: response.data.giftCardResponse.code,
                };
                dispatch(setGiftCard(gifCard));
              }
            }
          })
          .catch((e) => {
            setLoadingPromotion(false);
            setErrors({ ...errors, ['promoCode']: e.response.data });
            dispatch(setPromoCodeToCart(null));
          });
      })
      .catch(setErrors);
  };

  const getPromotionRestrictedDateText = () => {
    if (restrictedDates?.length > 0) {
      return (
        <>
          However{' '}
          {restrictedDates.map((restrictedDate, key) => {
            console.log(key);
            return (
              <b key={key}>{restrictedDate + (key + 1 == restrictedDates.length ? '' : ', ')}</b>
            );
          })}
          {restrictedDates?.length > 1 ? ' are blackout dates.' : ' is a blackout date.'}
        </>
      );
    }
    return '';
  };

  return (
    <Form className={classes.root}>
      <Form.Group widths={2}>
        <Form.Field
          id="promoCode"
          control={Input}
          label="Promotion Discount"
          type="text"
          onChange={onChange}
          value={promoCode}
          error={!!errors?.promoCode && !cart?.promoCode}
          placeholder="Gift Card / Promotion Code"
        />
        {!cart?.promoCode && !cart?.giftCard && (
          <Form.Field
            control={Button}
            label="&nbsp;"
            onClick={onClickSubmit}
            type="button"
            color="green"
            content="Apply Promotion"
            loading={loadingPromotion}
          />
        )}
      </Form.Group>
      {!!errors?.promoCode && !cart?.promoCode && <Message negative>{errors.promoCode}</Message>}
      {!!cart?.promoCode && (
        <Message positive>
          Promotion {cart.promoCode} applied ! {getPromotionRestrictedDateText()}{' '}
        </Message>
      )}
      {!!cart?.giftCard && <Message positive>GiftCard {cart.giftCard.code} applied !</Message>}
    </Form>
  );
};

export default React.memo(PromoCodePanel);
