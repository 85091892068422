import api from './api';

const URLS = {
  listRoomTypeByAvailability: 'bookingSearchByDates',
  bookingSearchByDates: 'bookingSearchByDates',
  availability: 'availability'
};

export const listRoomTypeByAvailability = (body) => {
  return api.post(URLS.listRoomTypeByAvailability, body);
};

export const availability = (body) => {
  return api.post(URLS.availability, body);
};
