import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import classnames from 'classnames';
import WeatherItem from '@/components/common/WeatherItem';
import { forecast } from '@/api/weatherAPI';
import WeatherHelper from '@/helpers/weatherHelper';

const useStyles = createUseStyles(
  {
    weather: {
      opacity: 1,
      position: 'relative',
      transition: 'opacity 400ms ease-in-out',
      width: 'max-content',
      zIndex: 1,
    },
    weatherOverflow: {
      position: 'relative',
      width: '150px',
      height: '70px',
      overflow: 'hidden',
      '&.open': {
        width: '900px',
      },
    },
    [`@media (max-width: 1200px)`]: {
      weather: {
        display: 'none'
      },
    }
  },
  { name: 'WeatherPanel' },
);
const WeatherPanel = () => {
  const classes = useStyles();
  const [isOpenWeatherPanel, setOpenWeatherPanel] = useState(false);
  const [weatherData, setWeatherData] = useState();

  const openWeatherPanel = () => setOpenWeatherPanel(true);
  const closeWeatherPanel = () => setOpenWeatherPanel(false);

  useEffect(() => {
    forecast()
      .then((response) => {
        // console.log(response.data);
        let weatherData = WeatherHelper.processWeatherData(response.data);
        // console.log(weatherData);
        setWeatherData(weatherData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className={classes.weather}>
      <div
        className={classnames(classes.weatherOverflow, isOpenWeatherPanel ? 'open' : '')}
        onMouseEnter={openWeatherPanel}
        onMouseLeave={closeWeatherPanel}
      >
        {weatherData &&
          weatherData.map((weatherDataItem, idx) => (
            <WeatherItem
              key={idx}
              className={isOpenWeatherPanel || idx == 0 ? 'open' : ''}
              dateText={weatherDataItem.dateInformation}
              degreeText={weatherDataItem.temp}
              image={weatherDataItem.icon}
            ></WeatherItem>
          ))}
      </div>
    </div>
  );
};
export default React.memo(WeatherPanel);
