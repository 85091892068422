import React from 'react';
import { createUseStyles } from 'react-jss';
import { Grid, GridColumn, Form } from 'semantic-ui-react';
import Colors from '@/constants/colors';
import { searchSelector } from '@/store/selector';
import Moment from 'moment';
import '../common/Search.css';

const useStyles = createUseStyles(
  {
    grid: {
      height: '100%',
      marginTop: '0px !important',
      backgroundColor: Colors.whiteOne,
      cursor: 'pointer',
    },
    searchContainer: {
      width: '100% !important',
      height: '100%',
      borderTop: '1px solid #ccc',
      borderBottom: '1px solid #ccc',
      position: 'fixed',
      top: '0px',
      zIndex: '999',
      transform: 'translateY(calc(100% - 75px))',
      transition: 'transform 0.90s cubic-bezier(.7,1,.32,1)',
      backgroundColor: '#fff',
    },
    searchContainerActive: {
      composes: ['$searchContainer'],
      transform: 'translateY(0%)',
      '& $searchButton': {
        display: 'none !important',
      },
      '& $searchPanelBack': {
        display: 'block !important',
      },
    },
    searchCenter: {
      borderRight: `1px solid ${Colors.grayFour};`,
      paddingTop: '25px !important',
    },
    searchButton: {
      backgroundColor: Colors.blackTwo,
      color: Colors.whiteOne,
      height: '74px',
      paddingTop: '28px !important',
      cursor: 'pointer',
    },
    searchPanelBack: {
      padding: '25px !important',
      borderRight: '1px solid #ccc !important',
      paddingLeft: '60px !important',
      display: 'none !important',
    },
    searchPanelBackWithRoomType: {
      composes: ['$searchPanelBack'],
      borderRight: 'none !important',
    },
    searchViewAll: {
      padding: '25px !important',
      borderRight: '1px solid #ccc !important',
      paddingLeft: '60px !important',
      display: 'none !important',
    },
    searchViewAllActive: {
      composes: ['$searchViewAll'],
      display: 'block !important',
    },
    [`@media (max-width: 1200px)`]: {
      searchContainer: {
        display: 'none',
      },
    },
  },
  { name: 'SearchBottomBar' },
);
const SearchBottomBar = ({
  showResults,
  divClick,
  viewAllClick,
  backClick,
  onSearchClick,
  loading,
  property,
  searchParams,
  adults,
  childenrs,
}) => {
  const classes = useStyles();
  const search = searchSelector();
  return (
    <div
      className={showResults === true ? [classes.searchContainerActive] : classes.searchContainer}
    >
      <Form className="search-form" loading={loading} onClick={divClick}>
        <Grid className={classes.grid} columns="equal">
          <GridColumn
            className={
              search.roomType !== null && showResults
                ? classes.searchPanelBackWithRoomType
                : classes.searchPanelBack
            }
            textAlign="left"
            onClick={backClick}
          >
            <i className="arrow left icon"></i> Back
          </GridColumn>
          <GridColumn
            className={
              search.roomType !== null && showResults
                ? classes.searchViewAllActive
                : classes.searchViewAll
            }
            textAlign="right"
            onClick={viewAllClick}
          >
            View All Rooms
          </GridColumn>
          <GridColumn className="resorts" textAlign="left">
            <div
              className={
                search.roomType != null
                  ? 'search-property-name-large'
                  : 'search-property-name-short'
              }
            >
              {property.id != null ? property.name : 'All Resorts'}{' '}
              {search.roomType != null ? '(' + search.roomType.name + ')' : ''}
            </div>
          </GridColumn>
          <GridColumn className={classes.searchCenter} textAlign="center">
            <Grid columns={3}>
              <GridColumn textAlign="center">
                {searchParams.arrival != null
                  ? Moment(searchParams.arrival).format('MM/D/YYYY')
                  : 'Arrival'}
              </GridColumn>
              <GridColumn textAlign="center">
                <i className="arrow right icon"></i>{' '}
              </GridColumn>
              <GridColumn textAlign="center">
                {searchParams.departure != null
                  ? Moment(searchParams.departure).format('MM/D/YYYY')
                  : 'Departure'}
              </GridColumn>
            </Grid>
          </GridColumn>
          <GridColumn textAlign="center">
            <Grid columns="equal">
              <GridColumn textAlign="center" className="guest-section">
                <label>{childenrs + adults}</label> Guests
              </GridColumn>
              <GridColumn
                onClick={onSearchClick}
                className={classes.searchButton}
                textAlign="center"
              >
                Search
              </GridColumn>
            </Grid>
          </GridColumn>
        </Grid>
      </Form>
    </div>
  );
};
export default React.memo(SearchBottomBar);
