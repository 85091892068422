import api from './api';

const axiosParams = {
  baseURL: 'https://api.openweathermap.org/data/2.5/',
};

const URLS = {
  forecast: 'forecast',
};

const key = 'ddfe1a45aad3f7b0b4a251e9ca815177';
const id = '5127321';

export const forecast = () => {
  return api.get(URLS.forecast + '?id=' + id + '&appid=' + key + '&units=imperial', axiosParams);
};
