import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import BookingEngineLayout from '@/layout/BookingEngineLayout';
import Search from '@/components/common/Search';
import Top from '@/components/common/Top';
// import RoomTypeItem from '../../components/common/RoomTypeItem';
import { getPropertyData } from '@/api/propertyAPI';
import { createUseStyles } from 'react-jss';
// import { searchSelector } from '@/store/selector';

const useStyles = createUseStyles(
  {
    root: {
      width: '100%',
    },
    roomTypeItemContainer: {
      marginTop: '20px',
    },
    image: {
      width: '100%',
    },
    content: {
      marginLeft: '30px',
      width: '100%',
    },
  },
  { name: 'AvailabilityView' },
);

const AvailabilityView = () => {
  const classes = useStyles();
  const location = useLocation();
  const parameters = new URLSearchParams(location.search);
  var params = {
    propertyId: parameters.get('property'),
  };
  // const search = searchSelector();
  const [property, setProperty] = useState({});

  useEffect(() => {
    getPropertyData(params)
      .then((response) => {
        setProperty(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <BookingEngineLayout showCustomTop={true}>
      <div className={classes.root}>
        <Top showMenu={true}></Top>
        <div>
          <img alt="" className={classes.image} src={property.heroImage}></img>
        </div>

      </div>
      <Search></Search>
    </BookingEngineLayout>
  );
};

export default React.memo(AvailabilityView);
