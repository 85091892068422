import React from 'react';
import { createUseStyles } from 'react-jss';
import { searchSelector } from '@/store/selector';

const useStyles = createUseStyles(
  {
    root: {
      // display: 'flex',
      minHeight: '100vh',
      height: '100%',
    },
    rootWithOutScrollBar: {
      composes: ['$root'],
      overflow: 'scroll',
        '&::-webkit-scrollbar': {
          display: 'none',
        }
    }
  },
  { name: 'BookingEngineLayout' },
);

const BookingEngineLayout = ({ children }) => {
  const classes = useStyles();
  const search = searchSelector();
  
  return (
    <div className={search.resultPanelOpen ? classes.rootWithOutScrollBar : classes.root}>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default React.memo(BookingEngineLayout);
