import React from 'react';
import { createUseStyles } from 'react-jss';
import { cartSelector } from '@/store/selector';
import CartItem from './CartItem';

const useStyles = createUseStyles({
  CartItems: {
    position: 'relative',
    '& > first-child': {
      margin: 0,
    },
  },
});

const CartItemList = (props) => {
  const classes = useStyles();
  const cart = cartSelector();

  const buildCartItem = (item, index) => {
    var itemList = new Array();
    for (var i = 0; i < item.numberOfRooms; i++) {
      itemList.push(
        <CartItem
          readOnly={props.readOnly}
          key={item.roomType.id + i + index}
          item={item}
          onRemoveItem={props.onRemoveItem}
        ></CartItem>,
      );
    }
    return itemList;
  };

  return (
    <div className={classes.CartItems}>
      {cart.items.length > 0 &&
        cart.items.map((item, idx) => {
          return buildCartItem(item, idx).map((itemFinal) => {
            return itemFinal;
          });
        })}
    </div>
  );
};

export default React.memo(CartItemList);
